import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { forwardRef, Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { FaIconComponent, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgxContentLoadingModule } from 'ngx-content-loading';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { CookiePolicyComponent } from '../cookie-policy/cookie-policy.component';
import { NavigationIndicatorIndeterminateComponent } from '../dashboard-area/navigation-indicator/navigation-indicator.component';
import { CustomerLinkComponent } from '../widget/customer-link/customer-link.component';
import { LocationLinkComponent } from '../widget/location-link/location-link.component';
import { PartnerLinkComponent } from '../widget/partner-link/partner-link.component';
import { ThingLinkComponent } from '../widget/thing-link/thing-link.component';
import { ActionAdvancedSearchComponent } from './advanced-search/action-advanced-search/action-advanced-search.component';
import { AdvancedSearchControlsComponent } from './advanced-search/advanced-search-controls.component';
import { AdvancedSearchDynamicInputsComponent } from './advanced-search/advanced-search-dynamic-inputs.component';
import { AlertAdvancedSearchAddPropertiesDialog } from './advanced-search/alert-advanced-search/alert-advanced-search-add-properties-dialog.component';
import { AlertAdvancedSearchComponent } from './advanced-search/alert-advanced-search/alert-advanced-search.component';
import { CustomerAdvancedSearchAddPropertiesDialog } from './advanced-search/customer-advanced-search/customer-advanced-search-add-properties-dialog.component';
import { CustomerAdvancedSearchComponent } from './advanced-search/customer-advanced-search/customer-advanced-search.component';
import { EventAdvancedSearchAddPropertiesDialog } from './advanced-search/event-advanced-search/event-advanced-search-add-properties-dialog.component';
import { EventAdvancedSearchComponent } from './advanced-search/event-advanced-search/event-advanced-search.component';
import { LocationAdvancedSearchComponent } from './advanced-search/location-advanced-search/location-advanced-search.component';
import { PartnerAdvancedSearchAddPropertiesDialog } from './advanced-search/partner-advanced-search/partner-advanced-search-add-properties-dialog.component';
import { PartnerAdvancedSearchComponent } from './advanced-search/partner-advanced-search/partner-advanced-search.component';
import { SimpleSearchImportDialogComponent } from './advanced-search/simple-search/simple-search-import-dialog.component';
import { SimpleSearchComponent } from './advanced-search/simple-search/simple-search.component';
import { ThingAdvancedSearchAddPropertiesDialog } from './advanced-search/thing-advanced-search/thing-advanced-search-add-properties-dialog.component';
import { ThingAdvancedSearchComponent } from './advanced-search/thing-advanced-search/thing-advanced-search.component';
import { WorkSessionAdvancedSearchAddPropertiesDialog } from './advanced-search/work-session-advanced-search/work-session-advanced-search-add-properties-dialog.component';
import { WorkSessionAdvancedSearchImportDialogComponent } from './advanced-search/work-session-advanced-search/work-session-advanced-search-import-dialog.component';
import { WorkSessionAdvancedSearchComponent } from './advanced-search/work-session-advanced-search/work-session-advanced-search.component';
import { BulkControlBar } from './component/bulk-control-bar/bulk-control-bar.component';
import { BulkDeleteDialogComponent } from './component/bulk-delete-dialog/bulk-delete-dialog.component';
import { BulkParentAssignDialogComponent } from './component/bulk-parent-assign-dialog/bulk-parent-assign-dialog.component';
import { BulkParentAssignDialogService } from './component/bulk-parent-assign-dialog/bulk-parent-assign-dialog.service';
import { BulkUpdateTagDialogComponent } from './component/bulk-update-tag-dialog/bulk-update-tag-dialog.component';
import { BulkUpdateTagDialogService } from './component/bulk-update-tag-dialog/bulk-update-tag-dialog.service';
import { CodeScannerAddonComponent } from './component/code-scanner-addon/code-scanner-addon.component';
import { CustomTitleDirective } from './component/custom-title.directive';
import { CustomerSearchFieldComponent } from './component/customer-search-field/customer-search-field.component';
import { DailySchedulerEditorComponent } from './component/daily-scheduler-editor/daily-scheduler-editor.component';
import { DaterangePickerV2Component } from './component/daterange-picker-v2/daterange-picker-v2.component';
import { DetailsPageDialogBreadcrumbComponent } from './component/details-page-dialog-breadcrumb/details-page-dialog-breadcrumb.component';
import { EmptyDataMessageComponent } from './component/empty-data-message.component';
import { FeedbackErrorComponent } from './component/feedback-error/feedback-error.component';
import { IncrementComponent } from './component/increment/increment.component';
import { BlobFieldComponent, CompositePartComponent, CustomLabelComponent, DaterangePickerComponent, DatetimeFieldComponent, DeleteAccountComponent, DynamicModalComponent, FRAME_CUSTOM_DIRECTIVES, FRAME_DIRECTIVES, ImageComponent, MessageComponent, MetricDetailComponent, ModalComponent, NumberFieldComponent, PeriodFieldComponent, PropertyComponent, StatisticComponent, TimerComponent } from './component/index';
import { InputFieldComponent } from './component/input-field/input-field.component';
import { ListOrderingDialogComponent } from './component/list-ordering-dialog/list-ordering-dialog.component';
import { MatSelectionFieldComponent } from './component/mat-selection-field/mat-selection-field.component';
import { PeriodFieldSelectorDialogComponent } from './component/period-field/period-field-selector-dialog.component';
import { PeriodFieldV2Component } from './component/period-field/period-field-v2.component';
import { ProfileAddLocationExceptionDialogComponent } from './component/profile-notifications/profile-add-location-exception-dialog.component';
import { ProfileNotificationsComponent } from './component/profile-notifications/profile-notifications.component';
import { RecipeImportDialogComponent } from './component/recipe-import-dialog/recipe-import-dialog.component';
import { ServiceLevelSubscriptionEditComponent } from './component/subscription/service-level-subscription-edit.component';
import { ServiceSubscriptionsTableComponent } from './component/subscription/service-subscriptions-table.component';
import { TabItemContentDirective } from './component/tabbed-page/tab-item-content.directive';
import { TabItemComponent } from './component/tabbed-page/tab-item.component';
import { TabbedPageComponent } from './component/tabbed-page/tabbed-page.component';
import { AddTagDialogComponent } from './component/tag-editor/add-tag-dialog.component';
import { DeleteTagDialogComponent } from './component/tag-editor/delete-tag-dialog.component';
import { EditTagDialogComponent } from './component/tag-editor/edit-tag-dialog.component';
import { TagEditor } from './component/tag-editor/tag-editor.component';
import { ThingDocumentLinkComponent } from './component/thing-document-link/thing-document-link.component';
import { TreeListComponent } from './component/tree-list/tree-list.component';
import { WidgetTitleComponent } from './component/widget-title/widget-title.component';
import { ConfirmDialog } from './confirm-dialog/confirm-dialog.component';
import { ContactEditorComponent } from './contacts/contact-editor.component';
import { ContactListComponent } from './contacts/contact-list.component';
import { CustomPropertyFormComponent } from './custom-property/custom-property-form.component';
import { ButtonTableValueComponent, CdkDetailRowDirective, CompositeTableValueComponent, CustomerLinkTableValueComponent, CustomTableComponent, CustomTableValueFactoryComponent, DatetimeTableValueComponent, LocationLinkTableValueComponent, MetricTableValueComponent, PipedTableValueComponent, SimpleTableValueComponent, SimpleUntranslatedTableValueComponent, TagTableValueComponent, TimerTableValueComponent } from './custom-table';
import { IconTableValueComponent } from './custom-table/custom-table-value/icon-table-value.component';
import { ImageTableValueComponent } from './custom-table/custom-table-value/image-table-value.component';
import { OrderLinkTableValueComponent } from './custom-table/custom-table-value/order-link-table-value.component';
import { ParentThingTableValueComponent } from './custom-table/custom-table-value/parent-thing-table-value.component';
import { PartnerLinkTableValueComponent } from './custom-table/custom-table-value/partner-link-table-value.component';
import { StoreOrderBuyerTableValueComponent } from './custom-table/custom-table-value/store-order-buyer-table-value.component';
import { ThingLinkTableValueComponent } from './custom-table/custom-table-value/thing-link-table-value.component';
import { VerifyChangesDirective } from './directive';
import { DownloadDialogComponent } from './download-dialog/download-dialog.component';
import { DownloadElementComponent } from './download-dialog/download-element.component';
import { DownloadProgressBarDialogComponent } from './download-progress-bar-dialog/download-progress-bar-dialog';
import { EditTableColumnsDialogComponent } from './editTableColumnsDialog/edit-table-columns-dialog.component';
import { FormControlComponent } from './form-control/form-control.component';
import { FormEditorComponent } from './form-editor/form-editor.component';
import { AdvancedSelectionComponent } from './form-editor/form-field-type/advanced-selection/advanced-selection.component';
import { ChoiceSubtreeComponent } from './form-editor/form-field-type/advanced-selection/choice-subtree.component';
import { SelectedSubtreeComponent } from './form-editor/form-field-type/advanced-selection/selected-subtree.component';
import { FormCheckboxFieldComponent } from './form-editor/form-field-type/form-checkbox-field.component';
import { FormDateFieldComponent } from './form-editor/form-field-type/form-date-field.component';
import { FormFieldWithPredicateComponent } from './form-editor/form-field-type/form-field-with-predicate.component';
import { FormFieldComponent } from './form-editor/form-field-type/form-field.component';
import { FormMatSelectionFieldComponent } from './form-editor/form-field-type/form-mat-selection-field.component';
import { FormOptionFieldComponent } from './form-editor/form-field-type/form-option-field.component';
import { FormPeriodFieldComponent } from './form-editor/form-field-type/form-period-field.component';
import { FormRadioFieldComponent } from './form-editor/form-field-type/form-radio-field.component';
import { FormSearchFieldComponent } from './form-editor/form-field-type/form-search-field.component';
import { FormSelectionFieldComponent } from './form-editor/form-field-type/form-selection-field.component';
import { FormSelectionKeyValueFieldComponent } from './form-editor/form-field-type/form-selection-key-value-field.component';
import { FormTagFieldComponent } from './form-editor/form-field-type/form-tag-field.component';
import { FormTextAreaComponent } from './form-editor/form-field-type/form-text-area.component';
import { FormTextFieldComponent } from './form-editor/form-field-type/form-text-field.component';
import { KeyValueEditorComponent } from './key-value-editor/key-value-editor.component';
import { ActiveListPaginator } from './list-paginator/active-list-paginator.component';
import { HistoricalListPaginator } from './list-paginator/historical-list-paginator.component';
import { MatTabGroupGestureModule } from './mat-tab-gesture/mat-tab-group-gesture.module';
import { ObjectArrayDialogComponent } from './object-array/object-array-dialog.component';
import { ObjectArrayTableComponent } from './object-array/object-array-table.component';
import { AlertSeverityPipe, BulkUpdateCounterPipe, BulkUpdateLocalizationPipe, CapitalizePipe, DateFormatterPipe, DatetimeFormatterPipe, DefaultCloudStatusPipe, DefaultCompositePartPipe, DefaultConnectionStatusPipe, DefaultGpsPositionPipe, DefaultServiceLevelPipe, DefaultSimpleValuePipe, DefaultTagPipe, DurationFormatterPipe, FileSizeFormatterPipe, FormErrorPipe, LoaderPipe, LocalizationPipe, MetricUnitFormatterPipe, ParentThingIdPipe, StatisticPipe, SuffixAndRoundPipe, SuffixPipe, TagLocalizationPipe } from './pipe';
import { ByteFormatterPipe } from './pipe/byte-formatter.pipe';
import { DefaultCommandOnConditionPipe } from './pipe/default-command-on-condition.pipe';
import { DefaultSimSessionStatusPipe } from './pipe/default-sim-session-status.pipe';
import { ElapsedTimePipe } from './pipe/elapsed-time.pipe';
import { FirmwareUpdateStatusPipe } from './pipe/firmware-update-status.pipe';
import { ThingInfoPipe } from './pipe/thingInfo.pipe';
import { UnderscoreRemoverPipe } from './pipe/underscore-remover.pipe';
import { SubPathResolverService } from './resolvers/sub-path-resolver.service';
import { ActionsPropertySearchInputComponent } from './search-field/actions-search-field/actions-property-search-input.component';
import { ActionsSearchFieldComponent } from './search-field/actions-search-field/actions-search-field.component';
import { AlertsPropertySearchInputComponent } from './search-field/alerts-search-field/alerts-property-search-input.component';
import { AlertsSearchFieldComponent } from './search-field/alerts-search-field/alerts-search-field.component';
import { CustomersPropertySearchInputComponent } from './search-field/customers-search-field/customers-property-search-input.component';
import { CustomersSearchFieldComponent } from './search-field/customers-search-field/customers-search-field.component';
import { EventsPropertySearchInputComponent } from './search-field/events-search-field/events-property-search-input.component';
import { EventsSearchFieldComponent } from './search-field/events-search-field/events-search-field.component';
import { LocationsPropertySearchInputComponent } from './search-field/locations-search-field/locations-property-search-input.component';
import { LocationsSearchFieldComponent } from './search-field/locations-search-field/locations-search-field.component';
import { PartnersPropertySearchInputComponent } from './search-field/partners-search-field/partners-property-search-input.component';
import { PartnersSearchFieldComponent } from './search-field/partners-search-field/partners-search-field.component';
import { SearchFieldComponent } from './search-field/search-field.component';
import { SearchFieldService } from './search-field/search-field.service';
import { SearchInputComponent } from './search-field/search-input/search-input.component';
import { SelectionInputComponent } from './search-field/selection-input/selection-input.component';
import { ThingSelectionInputDialogComponent } from './search-field/selection-input/thing-selection-input-dialog/thing-selection-input-dialog.component';
import { ThingsPropertySearchInputComponent } from './search-field/things-search-field/things-property-search-input.component';
import { ThingsSearchFieldComponent } from './search-field/things-search-field/things-search-field.component';
import { WorkSessionsPropertySearchInputComponent } from './search-field/work-sessions-search-field/work-sessions-property-search-input.component';
import { WorkSessionsSearchFieldComponent } from './search-field/work-sessions-search-field/work-sessions-search-field.component';
import { SingleValueEditorComponent } from './single-value-editor/single-value-editor.component';
import { ThingsFilterFieldComponent } from './things-filter-field/things-filter-field.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatTabsModule,
        MatMenuModule,
        FormsModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSliderModule,
        MatSlideToggleModule,
        FontAwesomeModule,
        MatTableModule,
        MatSelectModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        MatBadgeModule,
        MatPaginatorModule,
        MatSortModule,
        MatButtonToggleModule,
        DragDropModule,
        MatTreeModule,
        MatTabGroupGestureModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        MatExpansionModule,
        GooglePlaceModule,
        NgxContentLoadingModule,
        MatAutocompleteModule,
        MatDialogModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatDividerModule,
        MatSnackBarModule,
        MatStepperModule
    ],
    declarations: [
        DatetimeFieldComponent,
        DatetimeFormatterPipe,
        DynamicModalComponent,
        DefaultSimpleValuePipe,
        ImageComponent,
        LoaderPipe,
        LocalizationPipe,
        MetricDetailComponent,
        MetricUnitFormatterPipe,
        DefaultGpsPositionPipe,
        DefaultServiceLevelPipe,
        DefaultConnectionStatusPipe,
        DefaultCloudStatusPipe,
        DefaultCompositePartPipe,
        CapitalizePipe,
        FeedbackErrorComponent,
        FormErrorPipe,
        FRAME_DIRECTIVES,
        FRAME_CUSTOM_DIRECTIVES,
        ModalComponent,
        PropertyComponent,
        MessageComponent,
        VerifyChangesDirective,
        CookiePolicyComponent,
        BlobFieldComponent,
        NumberFieldComponent,
        FormControlComponent,
        CustomPropertyFormComponent,
        ContactListComponent,
        ContactEditorComponent,
        CustomLabelComponent,
        CustomTitleDirective,
        CompositePartComponent,
        NavigationIndicatorIndeterminateComponent,
        DaterangePickerComponent,
        EmptyDataMessageComponent,
        FormEditorComponent,
        FormFieldComponent,
        FormOptionFieldComponent,
        FormTextFieldComponent,
        FormDateFieldComponent,
        FormSelectionFieldComponent,
        FormSelectionKeyValueFieldComponent,
        FormRadioFieldComponent,
        FormCheckboxFieldComponent,
        FormTextAreaComponent,
        AdvancedSelectionComponent,
        ChoiceSubtreeComponent,
        SelectedSubtreeComponent,
        DownloadDialogComponent,
        DownloadElementComponent,
        DeleteAccountComponent,
        PeriodFieldComponent,
        PeriodFieldV2Component,
        PeriodFieldSelectorDialogComponent,
        StatisticComponent,
        FileSizeFormatterPipe,
        ThingDocumentLinkComponent,
        CustomTableComponent,
        CustomTableValueFactoryComponent,
        CdkDetailRowDirective,
        SuffixPipe,
        SuffixAndRoundPipe,
        TimerComponent,
        DurationFormatterPipe,
        PipedTableValueComponent,
        TimerTableValueComponent,
        CustomerLinkTableValueComponent,
        LocationLinkTableValueComponent,
        OrderLinkTableValueComponent,
        StoreOrderBuyerTableValueComponent,
        ButtonTableValueComponent,
        SimpleTableValueComponent,
        MetricTableValueComponent,
        CompositeTableValueComponent,
        TagTableValueComponent,
        ParentThingTableValueComponent,
        IconTableValueComponent,
        UnderscoreRemoverPipe,
        RecipeImportDialogComponent,
        TagLocalizationPipe,
        DateFormatterPipe,
        BulkUpdateCounterPipe,
        DefaultTagPipe,
        RecipeImportDialogComponent,
        ThingInfoPipe,
        CustomerLinkComponent,
        LocationLinkComponent,
        DailySchedulerEditorComponent,
        KeyValueEditorComponent,
        ParentThingIdPipe,
        SimpleUntranslatedTableValueComponent,
        ServiceLevelSubscriptionEditComponent,
        ServiceSubscriptionsTableComponent,
        FormTagFieldComponent,
        StatisticPipe,
        BulkUpdateLocalizationPipe,
        DatetimeTableValueComponent,
        FormFieldWithPredicateComponent,
        CodeScannerAddonComponent,
        FormMatSelectionFieldComponent,
        FormSearchFieldComponent,
        SingleValueEditorComponent,
        TreeListComponent,
        ImageTableValueComponent,
        DefaultCommandOnConditionPipe,
        IncrementComponent,
        DefaultSimSessionStatusPipe,
        ElapsedTimePipe,
        ByteFormatterPipe,
        TabbedPageComponent,
        TabItemComponent,
        TabItemContentDirective,
        AlertSeverityPipe,
        FormPeriodFieldComponent,
        ListOrderingDialogComponent,
        CustomerSearchFieldComponent,
        BulkUpdateTagDialogComponent,
        BulkParentAssignDialogComponent,
        DownloadProgressBarDialogComponent,
        ConfirmDialog,
        ProfileNotificationsComponent,
        ProfileAddLocationExceptionDialogComponent,
        TagEditor,
        AddTagDialogComponent,
        EditTagDialogComponent,
        DeleteTagDialogComponent,
        BulkDeleteDialogComponent,
        ObjectArrayTableComponent,
        ObjectArrayDialogComponent,
        DaterangePickerV2Component,
        WidgetTitleComponent,
        ThingLinkComponent,
        InputFieldComponent,
        BulkControlBar,
        PartnerLinkTableValueComponent,
        ThingLinkTableValueComponent,
        ThingLinkComponent,
        PartnerLinkComponent,
        DetailsPageDialogBreadcrumbComponent,
        FirmwareUpdateStatusPipe,
        SearchFieldComponent,
        WorkSessionsSearchFieldComponent,
        WorkSessionsPropertySearchInputComponent,
        ThingsSearchFieldComponent,
        CustomersSearchFieldComponent,
        LocationsSearchFieldComponent,
        PartnersSearchFieldComponent,
        ActionsSearchFieldComponent,
        AlertsSearchFieldComponent,
        EventsSearchFieldComponent,
        SearchInputComponent,
        ThingsPropertySearchInputComponent,
        LocationsPropertySearchInputComponent,
        CustomersPropertySearchInputComponent,
        AlertsPropertySearchInputComponent,
        EventsPropertySearchInputComponent,
        PartnersPropertySearchInputComponent,
        SelectionInputComponent,
        ThingSelectionInputDialogComponent,
        AdvancedSearchControlsComponent,
        ThingAdvancedSearchAddPropertiesDialog,
        AdvancedSearchDynamicInputsComponent,
        LocationAdvancedSearchComponent,
        ThingAdvancedSearchComponent,
        SimpleSearchComponent,
        SimpleSearchImportDialogComponent,
        CustomerAdvancedSearchComponent,
        CustomerAdvancedSearchAddPropertiesDialog,
        AlertAdvancedSearchComponent,
        EventAdvancedSearchComponent,
        AlertAdvancedSearchAddPropertiesDialog,
        EventAdvancedSearchAddPropertiesDialog,
        WorkSessionAdvancedSearchComponent,
        WorkSessionAdvancedSearchAddPropertiesDialog,
        WorkSessionAdvancedSearchImportDialogComponent,
        PartnerAdvancedSearchComponent,
        PartnerAdvancedSearchAddPropertiesDialog,
        ThingsFilterFieldComponent,
        EditTableColumnsDialogComponent,
        MatSelectionFieldComponent,
        ActionAdvancedSearchComponent,
        ActionsPropertySearchInputComponent,
        ActiveListPaginator,
        HistoricalListPaginator
    ],
    exports: [
        DatetimeFieldComponent,
        DatetimeFormatterPipe,
        DynamicModalComponent,
        DefaultSimpleValuePipe,
        ImageComponent,
        LoaderPipe,
        LocalizationPipe,
        MetricDetailComponent,
        MetricUnitFormatterPipe,
        DefaultGpsPositionPipe,
        DefaultServiceLevelPipe,
        DefaultConnectionStatusPipe,
        DefaultCloudStatusPipe,
        DefaultCompositePartPipe,
        CapitalizePipe,
        FeedbackErrorComponent,
        FormErrorPipe,
        FRAME_DIRECTIVES,
        FRAME_CUSTOM_DIRECTIVES,
        ModalComponent,
        PropertyComponent,
        CommonModule,
        MessageComponent,
        VerifyChangesDirective,
        CookiePolicyComponent,
        BlobFieldComponent,
        NumberFieldComponent,
        FormControlComponent,
        CustomPropertyFormComponent,
        ContactListComponent,
        ContactEditorComponent,
        CustomLabelComponent,
        CustomTitleDirective,
        CompositePartComponent,
        NavigationIndicatorIndeterminateComponent,
        DaterangePickerComponent,
        EmptyDataMessageComponent,
        FormEditorComponent,
        FormFieldComponent,
        FormOptionFieldComponent,
        FormTextFieldComponent,
        FormDateFieldComponent,
        FormSelectionFieldComponent,
        FormSelectionKeyValueFieldComponent,
        FormRadioFieldComponent,
        FormCheckboxFieldComponent,
        FormTextAreaComponent,
        MatProgressBarModule,
        MatTabsModule,
        MatTabGroupGestureModule,
        MatMenuModule,
        AdvancedSelectionComponent,
        DownloadDialogComponent,
        FormsModule,
        DeleteAccountComponent,
        PeriodFieldComponent,
        PeriodFieldV2Component,
        PeriodFieldSelectorDialogComponent,
        StatisticComponent,
        FileSizeFormatterPipe,
        MatRadioModule,
        MatCheckboxModule,
        MatSliderModule,
        MatSlideToggleModule,
        FaIconComponent,
        ThingDocumentLinkComponent,
        CustomTableComponent,
        CustomTableValueFactoryComponent,
        SuffixPipe,
        SuffixAndRoundPipe,
        TimerComponent,
        DurationFormatterPipe,
        UnderscoreRemoverPipe,
        RecipeImportDialogComponent,
        MatSelectModule,
        MatCardModule,
        MatButtonModule,
        MatTableModule,
        TagLocalizationPipe,
        DateFormatterPipe,
        BulkUpdateCounterPipe,
        DefaultTagPipe,
        MatButtonToggleModule,
        MatIconModule,
        MatBadgeModule,
        ThingInfoPipe,
        CustomerLinkComponent,
        LocationLinkComponent,
        MatPaginatorModule,
        MatSortModule,
        DailySchedulerEditorComponent,
        KeyValueEditorComponent,
        ParentThingIdPipe,
        ServiceLevelSubscriptionEditComponent,
        ServiceSubscriptionsTableComponent,
        FormTagFieldComponent,
        StatisticPipe,
        BulkUpdateLocalizationPipe,
        DatetimeTableValueComponent,
        FormFieldWithPredicateComponent,
        CodeScannerAddonComponent,
        FormMatSelectionFieldComponent,
        FormSearchFieldComponent,
        SingleValueEditorComponent,
        TreeListComponent,
        DefaultCommandOnConditionPipe,
        DragDropModule,
        MatDividerModule,
        MatInputModule,
        MatTooltipModule,
        MatDialogModule,
        MatStepperModule,
        MatExpansionModule,
        IncrementComponent,
        GooglePlaceModule,
        DefaultSimSessionStatusPipe,
        ElapsedTimePipe,
        ByteFormatterPipe,
        NgxContentLoadingModule,
        TabbedPageComponent,
        TabItemComponent,
        TabItemContentDirective,
        AlertSeverityPipe,
        FormPeriodFieldComponent,
        ListOrderingDialogComponent,
        CustomerSearchFieldComponent,
        BulkUpdateTagDialogComponent,
        BulkParentAssignDialogComponent,
        DownloadProgressBarDialogComponent,
        ConfirmDialog,
        ProfileNotificationsComponent,
        TagEditor,
        AddTagDialogComponent,
        DeleteTagDialogComponent,
        BulkDeleteDialogComponent,
        DaterangePickerV2Component,
        WidgetTitleComponent,
        ThingLinkComponent,
        InputFieldComponent,
        BulkControlBar,
        MatDatepickerModule,
        ThingLinkComponent,
        PartnerLinkComponent,
        DetailsPageDialogBreadcrumbComponent,
        FirmwareUpdateStatusPipe,
        SearchFieldComponent,
        WorkSessionsSearchFieldComponent,
        WorkSessionsPropertySearchInputComponent,
        ThingsSearchFieldComponent,
        CustomersSearchFieldComponent,
        LocationsSearchFieldComponent,
        PartnersSearchFieldComponent,
        ActionsSearchFieldComponent,
        AlertsSearchFieldComponent,
        EventsSearchFieldComponent,
        SearchInputComponent,
        ThingsPropertySearchInputComponent,
        LocationsPropertySearchInputComponent,
        CustomersPropertySearchInputComponent,
        AlertsPropertySearchInputComponent,
        EventsPropertySearchInputComponent,
        PartnersPropertySearchInputComponent,
        SelectionInputComponent,
        ThingSelectionInputDialogComponent,
        AdvancedSearchControlsComponent,
        ThingAdvancedSearchAddPropertiesDialog,
        AdvancedSearchDynamicInputsComponent,
        LocationAdvancedSearchComponent,
        ThingAdvancedSearchComponent,
        SimpleSearchComponent,
        SimpleSearchImportDialogComponent,
        CustomerAdvancedSearchComponent,
        CustomerAdvancedSearchAddPropertiesDialog,
        AlertAdvancedSearchComponent,
        EventAdvancedSearchComponent,
        AlertAdvancedSearchAddPropertiesDialog,
        EventAdvancedSearchAddPropertiesDialog,
        WorkSessionAdvancedSearchComponent,
        WorkSessionAdvancedSearchAddPropertiesDialog,
        WorkSessionAdvancedSearchImportDialogComponent,
        PartnerAdvancedSearchComponent,
        PartnerAdvancedSearchAddPropertiesDialog,
        ThingsFilterFieldComponent,
        EditTableColumnsDialogComponent,
        MatSelectionFieldComponent,
        ActionAdvancedSearchComponent,
        ActionsPropertySearchInputComponent,
        ActiveListPaginator,
        HistoricalListPaginator
    ],
    providers: [
        DurationFormatterPipe,
        SubPathResolverService,
        SearchFieldService,
        BulkUpdateTagDialogService,
        BulkParentAssignDialogService
    ]
})
export class SharedModule {

    constructor(@Inject(forwardRef(() => FaIconLibrary)) library: FaIconLibrary) {
        library.addIconPacks(fas);
        library.addIconPacks(fab);
        library.addIconPacks(far);
    }

    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: []
        };
    }
}