import { Component } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { CustomMatPaginatorIntl } from "../../dashboard-area/shared/custom-mat-paginator-intl.service";
import { ListRangeSelectionModeType } from "../../model";
import { ListPaginator } from "./list-paginator.component";

@Component({
    selector: 'active-list-paginator',
    template: require('./list-paginator.component.html'),
    styles: [require('./list-paginator.component.css')],
    providers: [{ provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }]
})
export class ActiveListPaginator extends ListPaginator {

    protected updatePaginatorClass(): void {
        if (this.authenticationService.getTenant().listRangeSelectionMode == ListRangeSelectionModeType.PAGES) {
            this.matPaginatorClass = "mat-paginator-pages-mode";
        }
    }
}