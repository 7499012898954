
export class SubscriptionSetPayment {
    id: string;
    customerId: string;
    partnerId: string;
    entityName: string;
    subscriptionSetId: string;
    status: SubscriptionSetPaymentStatus;
    amount: number;
    type: SubscriptionSetPaymentType;
    paymentNumber: string;
    items: SubscriptionSetPaymentItem[];
    creationTimestamp: number;
    dueTimestamp: number;
    paymentTimestamp: number;
    confirmationTimestamp: number;
    confirmationUserEmail: string;
}

export enum SubscriptionSetPaymentStatus {
    PENDING = 'PENDING',
    PAID = 'PAID'
}

export enum SubscriptionSetPaymentType {
    OTHER = 'OTHER'
}

export class SubscriptionSetPaymentItem {
    itemId: string;
    category: string;
    name: string;
    operation: string;
    amount: number;
}