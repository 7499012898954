import { HttpParams } from "@angular/common/http";
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewContainerRef, forwardRef } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import * as moment from "moment";
import { take } from "rxjs";
import { ErrorMessages } from "../../common/constants";
import { ActionState, ActionStatus, ActionTopic, Metric, ProductModelSparePartDefinitionReference, SparePartDefinition, Thing } from "../../model";
import { WearStatus } from "../../model/wear-status";
import { AuthenticationService } from "../../service/authentication.service";
import { DataService } from "../../service/data.service";
import { ProductModelSparePartDefinitionReferenceService } from "../../service/product-model-spare-part-definition-reference.service";
import { UserActionStatusService } from "../../service/user-action-status.service";
import { WearStatusService } from "../../service/wear-status.service";
import { LocalizationPipe } from "../../shared/pipe";
import { ErrorUtility } from "../../utility/error-utility";
import { ActionInfoDialogComponent } from "../action-info-dialog/action-info-dialog.component";
import { WearStatusWidgetElementDetailsDialogComponent } from "./wear-status-widget-element-details-dialog.component";

@Component({
    selector: 'wear-status-widget-element-details',
    template: require('./wear-status-widget-element-details.component.html'),
    styles: [require('./wear-status-widget-element-details.component.css')]
})
export class WearStatusWidgetElementDetailsComponent implements OnInit {

    @Input() wearMetric: Metric;

    @Input() liteUI: boolean;

    @Input() popupView: boolean;

    @Input() showLinks: boolean = true;

    @Input() showUsageInfo: boolean;

    @Input() thing: Thing;

    @Input() historicalWearStatus: WearStatus;

    @Output() refreshEvent = new EventEmitter();

    loaded: boolean;
    wearStatus: WearStatus;
    actionStatus: ActionStatus;
    sparePartDefinitionMap: { [id: string]: { sparePartDefinition: SparePartDefinition, quantity: number } };
    sparePartDefinitionsTooltipMessage: string;
    error: string;
    sparePartDefinitionCount: number;
    sparePartDefinitionImages: string[];
    exceedStandardUsage: boolean;
    replacementPrevisionMessage: string;
    progressMarkerPercentage: number;
    locale: string;
    digitOptions = { minimumFractionDigits: 0, maximumFractionDigits: 1 };

    constructor(
        @Inject(forwardRef(() => WearStatusService)) private wearStatusService: WearStatusService,
        @Inject(forwardRef(() => ProductModelSparePartDefinitionReferenceService)) private productModelSparePartDefinitionReferenceService: ProductModelSparePartDefinitionReferenceService,
        @Inject(forwardRef(() => UserActionStatusService)) private userActionStatusService: UserActionStatusService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
        @Inject(forwardRef(() => DataService)) private dataService: DataService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService
    ) { }

    ngOnInit(): void {
        const user = this.authenticationService.getUser()
        this.locale = user.locale || user.language || navigator.language;
        if (this.thing) {
            this.refresh();
        }
    }

    private refresh(): void {
        this.loaded = false;
        this.sparePartDefinitionsTooltipMessage = '';
        this.sparePartDefinitionImages = [];
        this.sparePartDefinitionCount = 0;
        this.sparePartDefinitionMap = {};
        this.replacementPrevisionMessage = '';
        this.progressMarkerPercentage = null;
        this.getWearStatuses().then(wearStatus => {
            this.wearStatus = wearStatus;
            if (this.wearStatus) {
                this.exceedStandardUsage = this.wearStatus.usage > (this.wearStatus.standardUsage);
                this.progressMarkerPercentage = this.wearStatus.standardUsage * (100 / (this.wearStatus.standardUsage + this.wearStatus.tolerance));
                if (!this.exceedStandardUsage && !this.liteUI && this.wearStatus.wear > 0) {
                    this.computeReplaceDaysMessage();
                }
                let promises = [];
                if (!this.liteUI) {
                    promises.push(this.getActionStatus());
                    if (this.wearStatus.productModelSparePartDefinitionReferenceIds?.length) {
                        promises.push(this.getProductModelSparePartDefinitionReferences(this.wearStatus.productModelSparePartDefinitionReferenceIds));
                    }
                }
                Promise.all(promises).then(() => {
                    this.loaded = true;
                }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
            } else {
                this.loaded = true;
            }
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
    }

    private computeReplaceDaysMessage() {
        this.dataService.getValues(this.wearMetric.name, this.thing.id, 2).then(resp => {
            let values = resp.values;
            if (!values?.length) {
                return;
            }
            let oldTimestamp, oldValue;
            const tolerance = 0.0001;
            if (Math.abs(values[0].value - this.wearStatus.wear) < tolerance) {
                if (!values[1] || values[1].value == null) {
                    return;
                }
                oldTimestamp = values[1].timestamp;
                oldValue = values[1].value;
            } else { // PUT was done on Wear Status but not on Wear Metric
                oldTimestamp = values[0].timestamp;
                oldValue = values[0].value;
            }
            if (oldValue > this.wearStatus.wear) {
                return;
            }
            const rate = (this.wearStatus.wear - oldValue) / (moment().valueOf() - oldTimestamp);
            if (rate <= 0) {
                return;
            }
            const total = (100 - this.wearStatus.wear) / rate;
            var duration = moment.duration(total, 'milliseconds');
            const numberOfDays = Math.round(duration.asDays());
            let defaultMessage = this.localizationPipe.transform('Expected replacement in ${value} days');
            this.replacementPrevisionMessage = defaultMessage.replace('${value}', numberOfDays > 0 ? numberOfDays.toString() : '< 1');
        })
    }

    private getWearStatuses(): Promise<WearStatus> {
        if (this.historicalWearStatus) {
            return Promise.resolve(this.historicalWearStatus);
        } else {
            return this.wearStatusService.getWearStatusesFromThingIdAndMetricId(this.thing.id, this.wearMetric.id).then(results => {
                return results?.length ? results[0] : null;
            });
        }
    }

    private getProductModelSparePartDefinitionReferences(referenceIds: string[]): Promise<any> {
        let params = new HttpParams();
        referenceIds.forEach(id => params = params.append('productModelSparePartDefinitionReferenceId', id));
        return this.productModelSparePartDefinitionReferenceService.getRecursivelyAllProductModelSparePartDefinitionReferences(null, null, params).then(references => {
            this.buildSparePartDefinitionMap(references);
            this.buildExtraDataFromMap();
        });
    }

    private buildSparePartDefinitionMap(references: ProductModelSparePartDefinitionReference[]): void {
        references?.forEach(ref => {
            this.sparePartDefinitionCount += ref.quantity;
            const oldQuantity: number = this.sparePartDefinitionMap[ref.sparePartDefinitionId] ? this.sparePartDefinitionMap[ref.sparePartDefinitionId].quantity : 0;
            this.sparePartDefinitionMap[ref.sparePartDefinitionId] = { sparePartDefinition: ref.sparePartDefinition, quantity: (oldQuantity + ref.quantity) };
        });
    }

    private buildExtraDataFromMap(): void {
        Object.keys(this.sparePartDefinitionMap).forEach(key => {
            // tooltip message
            const sparePartDefintionName = this.localizationPipe.transform(this.sparePartDefinitionMap[key].sparePartDefinition.name);
            const sparePartDefintionCode = this.sparePartDefinitionMap[key].sparePartDefinition.code;
            const quantity = this.sparePartDefinitionMap[key].quantity;
            this.sparePartDefinitionsTooltipMessage += `${quantity}x ${sparePartDefintionName} ${sparePartDefintionCode}\n`;

            // spare part definition images
            const image = this.sparePartDefinitionMap[key].sparePartDefinition.imageUrls?.length ? this.sparePartDefinitionMap[key].sparePartDefinition.imageUrls[0] : null;
            if (image && this.sparePartDefinitionImages.length < 3) {
                this.sparePartDefinitionImages.push(image);
            }
        });
    }

    private getActionStatus(): Promise<any> {
        let params = new HttpParams();
        params = params.set("state", ActionState.TODO);
        params = params.set("topic", ActionTopic.MAINTENANCE);
        params = params.set('page', 0);
        params = params.set('size', 1);
        params = params.set('sort', 'startTimestamp,desc');
        params = params.set('wearMetricId', this.wearMetric.id);
        params = params.set('thingId', this.thing.id);
        return this.userActionStatusService.getRecursivelyAllStatuses(0, null, params).then(statuses => {
            this.actionStatus = statuses?.length ? statuses[0] : null;
        });
    }

    openReplacementActionDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.maxWidth = '428px';
        dialogConfig.autoFocus = false;
        dialogConfig.data = { wearMetric: this.wearMetric, actionStatus: this.actionStatus, sparePartDefinitionMap: this.sparePartDefinitionCount > 0 ? this.sparePartDefinitionMap : null };
        dialogConfig.viewContainerRef = this.vcRef;
        this.dialog.open(ActionInfoDialogComponent, dialogConfig).afterClosed().pipe(take(1)).subscribe(refresh => {
            if (refresh) {
                this.popupView ? this.refreshEvent.emit() : this.refresh();
            }
        });
    }

    openDetailsDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.maxWidth = '428px';
        dialogConfig.autoFocus = false;
        dialogConfig.data = { wearMetric: this.wearMetric, thing: this.thing };
        dialogConfig.viewContainerRef = this.vcRef;
        this.dialog.open(WearStatusWidgetElementDetailsDialogComponent, dialogConfig).afterClosed().pipe(take(1)).subscribe(refresh => {
            if (refresh) {
                this.refresh();
            }
        });
    }

    isOverflow(el: HTMLElement): boolean {
        var isOverflowing = el.clientWidth < el.scrollWidth
            || el.clientHeight < el.scrollHeight;
        return isOverflowing;
    }

}