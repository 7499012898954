import { HttpParams } from '@angular/common/http';
import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Moment } from 'moment';
import { firstValueFrom } from 'rxjs';
import { PagedList, SubscriptionSetPayment, SubscriptionSetPaymentStatus } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { DateRangeName, DateRangeService } from '../../service/date-range.service';
import { SubscriptionSetService } from '../../service/subscription-set.service';
import { PreselectedRangeComponent } from '../../shared/component/daterange-picker/preselected-range.component';
import { CustomTableColumn, CustomTableService } from '../../shared/custom-table';
import { SuffixAndRoundPipe } from '../../shared/pipe';
import { ErrorUtility } from '../../utility/error-utility';
import { SubscriptionSetPaymentDetailsDialogComponent } from './subscription-set-payment-details-dialog.component';

@Component({
    selector: 'subscription-set-payment-list-widget',
    template: require('./subscription-set-payment-list-widget.component.html')
})
export class SubscriptionSetPaymentListWidgetComponent extends PreselectedRangeComponent implements OnInit {

    @Input() customerId: string;

    @Input() partnerId: string;

    @Input() hideFilters: boolean;

    loaded: boolean;
    error: string;
    payments: SubscriptionSetPayment[] = [];
    length: number;
    pageIndex: number = 0;
    pageSize: number = 50;
    sort: string[] = ['creationTimestamp', 'desc'];
    displayedColumns: CustomTableColumn[] = [
        CustomTableService.newSimpleColumn('paymentNumber', 'paymentNumberProperty' ,'paymentNumber').withSortField('paymentNumber'),
        CustomTableService.newDatetimeColumn('creationTimestamp', 'creationDateProperty', 'creationTimestamp', null, this.authenticationService.getUser().timezone).withSortField('creationTimestamp'),
        CustomTableService.newSimpleColumn('type', 'paymentMethodProperty' ,'type').withSortField('type'),
        CustomTableService.newPipedColumn('amount', 'amountProperty', 'amount', SuffixAndRoundPipe).withArgument(' €').withSortField('amount'),
        CustomTableService.newPipedColumn('status', 'statusProperty', 'status', 'underscoreRemover').withSortField('status')
            .withStyle({ 'PENDING': { 'color': '#DF2316' } }) // TODO: align when there will be a specific column type
    ];
    dataSource = new MatTableDataSource<SubscriptionSetPayment>([]);
    paymentStatus: SubscriptionSetPaymentStatus;
    filterPeriods = [DateRangeName.LAST_7_DAYS, DateRangeName.LAST_30_DAYS, DateRangeName.LAST_6_MONTHS, DateRangeName.LAST_12_MONTHS, 'CUSTOM'];
    initialRangeName = DateRangeName.LAST_30_DAYS;
        
    private searchText: string;
    private minCreationTimestamp: string;
    private maxCreationTimestamp: string;

    constructor(
        @Inject(forwardRef(() => SubscriptionSetService)) private subscriptionSetService: SubscriptionSetService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => DateRangeService)) protected dateRangeService: DateRangeService
    ) { super(dateRangeService); }


    ngOnInit(): void {
        this.getPagedPayments();
    }

    getPagedPayments(): void {
        this.loaded = false;
        this.getPayments().then(pagedList => {
            this.refreshTable(pagedList);
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err);
            this.loaded = true;
        });
    }

    private getPayments(): Promise<PagedList<SubscriptionSetPayment>> {
        let params = new HttpParams();
        if(!this.hideFilters){
            if(this.paymentStatus){
                params = params.set('status', this.paymentStatus);
            }
            if(this.maxCreationTimestamp) {
                params = params.set('maxCreationTimestamp', this.maxCreationTimestamp);    
            }
            if(this.minCreationTimestamp) {
                params = params.set('minCreationTimestamp', this.minCreationTimestamp);    
            }
            if(this.searchText){
                params = params.set('searchText', this.searchText);    
            }
        }
        return this.subscriptionSetService.getPagedPayments(this.pageIndex, this.pageSize, this.sort, this.customerId, this.partnerId, params);
    }

    private refreshTable(pagedList: PagedList<SubscriptionSetPayment>): void {
        this.payments = pagedList.content;
        this.length = pagedList.totalElements;
        this.pageIndex = pagedList.number;
        this.pageSize = pagedList.size;
        this.dataSource = new MatTableDataSource<SubscriptionSetPayment>(this.payments);
        this.loaded = true;
    }

    changePage(pageEvent: PageEvent): void {
        this.pageIndex = pageEvent.pageIndex;
        this.getPagedPayments();
    }

    changeSort(sort: Sort): void {
        this.sort = [sort.active, sort.direction];
        this.getPagedPayments();
    }

    selectPeriod(range: DateRange<Moment>) {
        this.pageIndex = 0;
        this.minCreationTimestamp = this.getStartDate(range);
        this.maxCreationTimestamp = this.getEndDate(range);
        this.getPagedPayments();
    }

    doSimpleSearch(body: any): void {
        this.searchText = body.key ? ('*' + body.key + '*') : '';
        this.pageIndex = 0;
        this.getPagedPayments();
    }

    openDetails(payment: SubscriptionSetPayment): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.width = '450px';
        dialogConfig.data = { payment: payment };
        firstValueFrom(this.dialog.open(SubscriptionSetPaymentDetailsDialogComponent, dialogConfig).afterClosed()).then(resp => {
            if(resp){
                this.getPagedPayments();
            }
        });
    }

}