import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { SUBSCRIPTION_SET_PAYMENTS_BY_ID, USER_ME_SUBSCRIPTION_CHANGE_LOGS, USER_ME_SUBSCRIPTION_SET, USER_ME_SUBSCRIPTION_SET_PAYMENTS, USER_ME_SUBSCRIPTION_SETS, USER_ME_SUBSCRIPTION_SETS_PAYMENT, USER_ME_SUBSCRIPTION_SETS_PAYMENT_PREVIEW, USER_ME_SUBSCRIPTION_SUMMARY } from '../common/endpoints';
import { DigitalServiceConfigurationType, PagedList, SubscriptionChangeLog, SubscriptionSet, SubscriptionSetStatus, SubscriptionSummary, VirtualSubscriptionSet } from '../model';
import { SubscriptionSetPayment } from '../model/subscription-set-payment';
import { HttpService } from './http.service';

@Injectable()
export class SubscriptionSetService {
    
    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService
    ) { }

    getSubscriptionSet(customerId?: string, partnerId?: string): Promise<SubscriptionSet> {
        let params = new HttpParams();
        if (customerId) {
            params = params.set('customerId', customerId);
        } else if (partnerId) {
            params = params.set('partnerId', partnerId);
        }
        return firstValueFrom(this.httpService.get<SubscriptionSet>(USER_ME_SUBSCRIPTION_SET, params));
    }

    getPagedVirualSubscriptionSets(type: DigitalServiceConfigurationType, page: number, size: number, sort: string[], searchText: string, status: SubscriptionSetStatus): Promise<PagedList<VirtualSubscriptionSet>> {
        let params = new HttpParams().set('type', type);
        if (sort?.length) {
            params = params.set('sort', sort.join(','));
        } else {
            params = params.set('sort', 'activationTimestamp, asc');
        }
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        if (status) {
            params = params.set('status', status);
        }
        return firstValueFrom(this.httpService.get<PagedList<VirtualSubscriptionSet>>(USER_ME_SUBSCRIPTION_SETS, params));
    }

    getSubscriptionSummary(type: DigitalServiceConfigurationType): Promise<SubscriptionSummary> {
        let params = new HttpParams().set('type', type);
        return firstValueFrom(this.httpService.get<SubscriptionSummary>(USER_ME_SUBSCRIPTION_SUMMARY, params));
    }

    createPayment(body: any, subscriptionSetHash: string, configurationHash: string): Promise<void> {
        let params = new HttpParams().set('subscriptionSetHash', subscriptionSetHash).set('configurationHash', configurationHash);
        return firstValueFrom(this.httpService.post<void>(USER_ME_SUBSCRIPTION_SETS_PAYMENT, body, params));
    }

    createPaymentPreview(body: any): Promise<PaymentPreviewResponse> {
        return firstValueFrom(this.httpService.post<PaymentPreviewResponse>(USER_ME_SUBSCRIPTION_SETS_PAYMENT_PREVIEW, body));
    }

    getRecursivelyAllPayments(page?: number, payments?: SubscriptionSetPayment[], customerId?: string, partnerId?: string, params?: HttpParams, sort?: string[]): Promise<SubscriptionSetPayment[]> {
        if (!page) {
            page = 0;
        }
        if (!payments) {
            payments = [];
        }
        if (!sort) {
            sort = ['creationTimestamp', 'desc'];
        }
        return this.getPagedPayments(page, 200, sort, customerId, partnerId, params)
            .then(pagedMetrics => {
                payments = payments.concat(pagedMetrics.content);
                if (pagedMetrics.last) {
                    return payments;
                } else {
                    return this.getRecursivelyAllPayments(++page, payments, customerId, partnerId, params, sort);
                }
            });
    }
    
    getPagedPayments(page: number, size: number, sort: string[], customerId?: string, partnerId?: string, params?: HttpParams): Promise<PagedList<SubscriptionSetPayment>> {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        params = params.set('sort', sort.join(','));
        if (customerId) {
            params = params.set('customerId', customerId + '');
        } else if (partnerId) {
            params = params.set('partnerId', partnerId + '');
        }
        return firstValueFrom(this.httpService.get<PagedList<SubscriptionSetPayment>>(USER_ME_SUBSCRIPTION_SET_PAYMENTS, params));
    }

    changeStatus(status: SubscriptionSetStatus, customerId?: string, partnerId?: string): Promise<void>  {
        let params = new HttpParams();
        if (customerId) {
            params = params.set('customerId', customerId);
        } else if (partnerId) {
            params = params.set('partnerId', partnerId);
        }
        return firstValueFrom(this.httpService.patch<void>(USER_ME_SUBSCRIPTION_SET, { status: status }, params));
    }

    getPagedChangeLogs(page: number, customerId?: string, partnerId?: string): Promise<PagedList<SubscriptionChangeLog>> {
        let params = new HttpParams();
        params = params.set('page', page + '');
        params = params.set('size', 10);
        params = params.set('sort', 'updateTimestamp,desc');
        if (customerId) {
            params = params.set('customerId', customerId + '');
        } else if (partnerId) {
            params = params.set('partnerId', partnerId + '');
        }
        return firstValueFrom(this.httpService.get<PagedList<SubscriptionChangeLog>>(USER_ME_SUBSCRIPTION_CHANGE_LOGS, params));
    }

    updatePaymentStatus(id: string, body: any): Promise<void> {
        return firstValueFrom(this.httpService.patch<void>(SUBSCRIPTION_SET_PAYMENTS_BY_ID.replace('{id}', id), body));
    }

}

class PaymentPreviewResponse { 
    payment: SubscriptionSetPayment; 
    renewalFee: number;
    changed: boolean;
    subscriptionSetHash: string; 
    configurationHash: string; 
}