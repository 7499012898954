import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { USER_ACTION_V2 } from '../../common/endpoints';
import { ActionStatus, PagedList } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService } from '../../service/custom-property.service';
import { HttpService } from '../../service/http.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { PropertyComponent } from '../../shared/component';
import { CustomTableColumn, CustomTableService } from '../../shared/custom-table';
import { AbstractListWidgetV2Service } from '../../shared/list-widget-v2/abstract-list-widget-v2.service';
import { ActionListWidgetService } from '../action-list/action-list-widget.service';

@Injectable()
export class ActionListWidgetV2Service extends ActionListWidgetService {

    private active: boolean;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => CustomPropertyService)) customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => AbstractContextService)) protected contextService: AbstractContextService,
        @Inject(forwardRef(() => AbstractThingContextService)) protected thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => HttpService)) protected httpService: HttpService
    ) {
        super(authenticationService, customPropertyService, contextService, thingContextService, httpService);
    }

    setActive(active: boolean): void {
        this.active = active;
    }

    getPagedList(page: number, size: number, sort: string[], searchFields: string[], advancedSearchBody: any): Promise<PagedList<ActionStatus>> {
        let params = this.getParams(searchFields, advancedSearchBody);
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        params = params.set('active', this.active);
        if (this.active) {
            if (sort && sort[0]) {
                if (sort[0].startsWith(AbstractListWidgetV2Service.COMPOSITE_SORT_PREFIX)) {
                    sort = this.assignCompositeSort(sort);
                }
                params = params.set('sort', sort.join(','));
            }
        }
        return firstValueFrom(this.httpService.get<PagedList<ActionStatus>>(USER_ACTION_V2, params));
    }

    private getParams(searchFields: string[], advancedSearchBody: any): HttpParams {
        let params = new HttpParams();
        const locationId = this.contextService.getCurrentLocation()?.id || this.authenticationService.getUser().locationId;
        const customerId = this.contextService.getCurrentCustomer()?.id || this.authenticationService.getUser().customerId;
        if (this.thingContextService.getCurrentThing()) {
            params = params.set('thingId', this.thingContextService.getCurrentThing().id);
        } else if (locationId) {
            params = params.set('locationId', locationId);
        } else if (customerId) {
            params = params.set('customerId', customerId);
        }
        if (advancedSearchBody) {
            if (advancedSearchBody.key) {
                params = params.set('searchText', "*" + advancedSearchBody.key + "*");
                searchFields.forEach(field => params = params.append('searchField', field));
            }
            if (advancedSearchBody.topics && advancedSearchBody.topics.length) {
                advancedSearchBody.topics.forEach(topic => params = params.append('topic', topic));
            }
            if (advancedSearchBody.actionDefinitions && advancedSearchBody.actionDefinitions.length) {
                advancedSearchBody.actionDefinitions.forEach(actionDefinitionId => params = params.append('actionDefinitionId', actionDefinitionId));
            }
            if (advancedSearchBody.priorities && advancedSearchBody.priorities.length) {
                advancedSearchBody.priorities.forEach(priority => params = params.append('priority', priority));
            }
            if (advancedSearchBody.statuses && advancedSearchBody.statuses.length) {
                advancedSearchBody.statuses.forEach(state => params = params.append('state', state));
            }
            if (advancedSearchBody.selectedThingIds && advancedSearchBody.selectedThingIds.length) {
                advancedSearchBody.selectedThingIds.forEach(id => params = params.append('selectedThingId', id));
            }
            if (advancedSearchBody.startTimestamp) {
                params = params.set('minStartTimestamp', advancedSearchBody.startTimestamp);
            }
            if (advancedSearchBody.endTimestamp) {
                params = params.set('maxStartTimestamp', advancedSearchBody.endTimestamp);
            }
        }
        return params;
    }

    protected getPropertyColumn(col: PropertyComponent, columnName: string, defaultType: string): CustomTableColumn {
        switch (col.name) {
            case 'customer.name':
                return CustomTableService.newCustomerLinkColumn(this.getLabel(col, defaultType), 'customer', 'customerId').withSortField(this.active ? col.name : null).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'location.name':
                return CustomTableService.newLocationLinkColumn(this.getLabel(col, defaultType), 'location', 'locationId').withSortField(this.active ? col.name : null).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'thing.serviceLevel':
                return CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), 'serviceLevel', this.getFilter(col, defaultType)).withSortField(this.active ? 'serviceLevel.level' : null).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'location.country':
            case 'location.timezone':
                return CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType))
                    .withAlternativePath(col.name.replace('location', 'customer')).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'thing.gpsPosition':
                return CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), 'gpsPosition', this.getFilter(col, defaultType))
                    .withAlternativePath('location.gpsPosition').withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'thing.tag':
                return CustomTableService.newTagColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType)).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'thing.parentThingId':
                return CustomTableService.newParentThingColumn(this.getLabel(col, defaultType), columnName).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'startTimestamp':
                return CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), 'startTimestamp', this.getFilter(col, defaultType)).withSortField(this.active ? col.name : null).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'icon':
                return CustomTableService.newImageColumn(columnName, '', 'actionDefinition.iconUrl').withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'thing.tags':
                return CustomTableService.newTagColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType)).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'topic':
                return CustomTableService.newIconColumn(columnName, '', 'actionDefinition.topic', this.getTopicIconMap()).withStyle({ '_any': { 'padding': '0px' } });
            case 'thing.name':
                return CustomTableService.newThingLinkColumn('thingName', this.getLabel(col, defaultType), 'thing', 'name').withSortField(this.active ? col.name : null).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'thing.serialNumber':
                return CustomTableService.newThingLinkColumn('thingSerialNumber', this.getLabel(col, defaultType), 'thing', 'serialNumber').withSortField(this.active ? col.name : null).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            case 'customer.partner.name':
            case 'location.partner.name':
            case 'thing.partner.name':
                return CustomTableService.newPartnerLinkColumn(this.getLabel(col, defaultType), col.name.substring(0, (col.name.length - ('name'.length) - 1))).withSortField(this.active ? col.name : null).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
            default:
                return CustomTableService.newPipedColumn(columnName, this.getLabel(col, defaultType), col.name, this.getFilter(col, defaultType)).withArgument(null, false, 'action', true)
                    .withSortField(this.active ? col.name : null).withValueMap(this.getValueMap(col)).withColumnClass(col.columnClass).withShowHeader(col.showHeader);
        }
    }

}