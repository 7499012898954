import { forwardRef, Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ACTION_DEFINITIONS } from '../common/endpoints';
import { ActionDefinition } from '../model';
import { HttpService } from './http.service';


@Injectable()
export class ActionDefinitionService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService
    ) { }

    getAllActionDefinition(): Promise<ActionDefinition[]> {
        return firstValueFrom(this.http.get<ActionDefinition[]>(ACTION_DEFINITIONS));
    }

}