import { HttpParams } from "@angular/common/http";
import { Component, Inject, ViewChild, forwardRef } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment";
import { firstValueFrom, take } from "rxjs";
import { ErrorMessages, SAVE_DATA_ERROR } from "../../common/constants";
import { USER_HISTORICAL_EVENTS_V2 } from "../../common/endpoints";
import { ActionState, ActionStatus, Alert, AlertDefinition, Metric, PagedList, ProductModelSparePartDefinitionReference, SparePartDefinition, StoreOrderItem, StoreOrderItemType, Thing } from "../../model";
import { WearStatus } from "../../model/wear-status";
import { AlertDefinitionService } from "../../service/alert-definition.service";
import { AuthenticationService } from "../../service/authentication.service";
import { HttpService } from "../../service/http.service";
import { MetricService } from "../../service/metric.service";
import { ProductModelSparePartDefinitionReferenceService } from "../../service/product-model-spare-part-definition-reference.service";
import { StoreCartService } from "../../service/store-cart.service";
import { UserActionStatusService } from "../../service/user-action-status.service";
import { WearStatusService } from "../../service/wear-status.service";
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";
import { MessageComponent } from "../../shared/component";
import { ConfirmDialog } from "../../shared/confirm-dialog/confirm-dialog.component";
import { CustomPropertyFormComponent } from "../../shared/custom-property/custom-property-form.component";
import { LocalizationPipe } from "../../shared/pipe";
import { ErrorUtility } from "../../utility/error-utility";

@Component({
    selector: 'action-info-dialog',
    template: require('./action-info-dialog.component.html'),
    styles: [require('./action-info-dialog.component.css')]
})
export class ActionInfoDialogComponent {

    @ViewChild('saveMessage') saveMessage: MessageComponent;

    @ViewChild('form') form: NgForm;

    @ViewChild('customPropForm') customPropForm: CustomPropertyFormComponent;

    wearMetric: Metric;
    actionStatus: ActionStatus;
    sparePartDefinitionMap: { [id: string]: { sparePartDefinition: SparePartDefinition, quantity: number } };
    showConfirmationForm: boolean;
    updating: boolean;
    error: string;
    actionDuration: string;
    locale: string;
    timezone: string;
    confirmReplacementEnabled: boolean;
    addToCartEnabled: boolean;
    isCustomerUser: boolean;
    loaded: boolean;
    defaultMaintenanceNotes: string;
    showMaintenancePropertyForm: boolean;
    showWearDetails: boolean;
    isHistorical: boolean;

    private thing: Thing;
    private bindedToMaintenanceEvent: boolean;

    constructor(
        @Inject(forwardRef(() => StoreCartService)) private storeCartService: StoreCartService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => UserActionStatusService)) private userActionStatusService: UserActionStatusService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => AlertDefinitionService)) private alertDefinitionService: AlertDefinitionService,
        @Inject(forwardRef(() => WearStatusService)) private wearStatusService: WearStatusService,
        @Inject(forwardRef(() => ProductModelSparePartDefinitionReferenceService)) private productModelSparePartDefinitionReferenceService: ProductModelSparePartDefinitionReferenceService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<ActionInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => MetricService)) private metricService: MetricService
    ) {
        this.isHistorical = data.isHistorical;
        this.wearMetric = data.wearMetric;
        this.actionStatus = data.actionStatus;
        this.sparePartDefinitionMap = data.sparePartDefinitionMap;
        this.thing = this.actionStatus.thing;
        this.thing.id = this.actionStatus.thingId
        const user = this.authenticationService.getUser();
        this.locale = user.locale || user.language || 'en';
        this.timezone = user.timezone || 'UTC';
        this.actionDuration = this.getActionDuration();
        this.addToCartEnabled = this.storeCartService.canAddToCart() && !this.isHistorical;
        this.isCustomerUser = this.authenticationService.isCustomerUser();
        if (!this.wearMetric) {
            this.retrieveSparePartDefinition();
        } else {
            this.setDefaultMaintenanceNotes();
            this.loaded = true;
        }
        this.bindedToMaintenanceEvent = !!this.actionStatus.actionDefinition.doneEventDefinitionId;
    }

    private retrieveSparePartDefinition(): void {
        if (this.actionStatus?.actionDefinition?.alertDefinitionIds?.length) {
            let promises = [];
            this.actionStatus.actionDefinition.alertDefinitionIds.forEach(id => promises.push(this.alertDefinitionService.getAlertDefinitionById(id)));
            Promise.all(promises).then((alertDefinitions: AlertDefinition[]) => {
                const wearMetricId = alertDefinitions.find(ad => ad.activeConditionWearMetricId)?.activeConditionWearMetricId;
                if (wearMetricId) {
                    firstValueFrom(this.metricService.getMetricById(wearMetricId)).then(wearMetric => {
                        if (wearMetric) {
                            this.wearMetric = wearMetric;
                            this.showWearDetails = true;
                            this.getWearStatus(wearMetricId).then(wearStatus => {
                                if (wearStatus?.productModelSparePartDefinitionReferenceIds?.length) {
                                    this.getProductModelSparePartDefinitionReferences(wearStatus.productModelSparePartDefinitionReferenceIds).then(references => {
                                        this.buildSparePartDefinitionMap(references);
                                        this.setDefaultMaintenanceNotes();
                                        this.loaded = true;
                                    }).catch(err => this.handleError(err));
                                } else {
                                    this.loaded = true;
                                }
                            });
                        }
                    }).catch(err => this.handleError(err));
                } else {
                    this.loaded = true;
                }
            }).catch(err => this.handleError(err));
        } else {
            this.loaded = true;
        }
    }

    private getWearStatus(wearMetricId: string): Promise<WearStatus> {
        if (this.isHistorical) {
            return Promise.resolve(this.actionStatus.wearStatus);
        } else {
            return this.wearStatusService.getWearStatusesFromThingIdAndMetricId(this.thing.id, wearMetricId).then(results => {
                return results?.length ? results[0] : null;
            });
        }
    }

    private handleError(err): void {
        this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
    }

    private getProductModelSparePartDefinitionReferences(referenceIds: string[]): Promise<any> {
        let params = new HttpParams();
        referenceIds.forEach(id => params = params.append('productModelSparePartDefinitionReferenceId', id));
        return this.productModelSparePartDefinitionReferenceService.getRecursivelyAllProductModelSparePartDefinitionReferences(null, null, params);
    }

    private buildSparePartDefinitionMap(references: ProductModelSparePartDefinitionReference[]): void {
        if (references?.length) {
            this.sparePartDefinitionMap = {};
            references?.forEach(ref => {
                const oldQuantity: number = this.sparePartDefinitionMap[ref.sparePartDefinitionId] ? this.sparePartDefinitionMap[ref.sparePartDefinitionId].quantity : 0;
                this.sparePartDefinitionMap[ref.sparePartDefinitionId] = { sparePartDefinition: ref.sparePartDefinition, quantity: (oldQuantity + ref.quantity) };
            });
        }
    }

    private getActionDuration(): string {
        let duration = moment.duration((this.isHistorical ? this.actionStatus.timestamp : new Date().getTime()) - this.actionStatus.startTimestamp);
        let numberOfDays = Math.round(duration.asDays());
        return (numberOfDays > 0 ? numberOfDays : '< 1') + " " + this.localizationPipe.transform("days");
    }

    private getSparePartDefinitionStoreItem(sparePartDefinitionId: string): StoreOrderItem {
        let newItem = new StoreOrderItem();
        newItem.itemId = sparePartDefinitionId;
        newItem.type = StoreOrderItemType.SPARE_PART;
        newItem.quantity = this.sparePartDefinitionMap[sparePartDefinitionId].quantity;
        newItem.thingId = this.thing.id;
        return newItem;
    }

    addToCart(): void {
        let newItems: StoreOrderItem[] = [];
        this.updating = true;
        Object.keys(this.sparePartDefinitionMap).forEach(key => {
            newItems.push(this.getSparePartDefinitionStoreItem(key));
        });
        this.storeCartService.addItemToCart(newItems).then(() => {
            this.updating = false;
            this.saveMessage.show();
            this.error = null;
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR));
    }

    confirmDiscard(): void {
        this.openConfirmDialog(ActionState.DISCARDED);
    }

    private discard(): void {
        this.updating = true;
        this.userActionStatusService.patchActionStatus(this.actionStatus, { state: ActionState.DISCARDED }).then(() => {
            this.updating = false;
            this.dialogRef.close(true);
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR));
    }

    markAsDone(): void {
        if (this.wearMetric) {
            this.showConfirmationForm = true;
        } else if (this.bindedToMaintenanceEvent) {
            this.showMaintenancePropertyForm = true;
        } else {
            this.openConfirmDialog(ActionState.DONE);
        }
    }

    back(): void {
        if (this.showMaintenancePropertyForm && (this.wearMetric)) {
            this.showConfirmationForm = true;
        } else {
            this.showConfirmationForm = false;
        }
        this.showMaintenancePropertyForm = false;
    }

    confirmReplacement(): void {
        if (this.bindedToMaintenanceEvent) {
            this.showConfirmationForm = false;
            this.showMaintenancePropertyForm = true;
        } else {
            this.confirm({ state: ActionState.DONE });
        }
    }

    confirmProperties(): void {
        this.confirm(this.getMaintenanceBody());
    }

    private confirm(body: any): void {
        this.updating = true;
        this.userActionStatusService.patchActionStatus(this.actionStatus, body).then(() => {
            this.getMaintenanceEvents().then(result => {
                const alerts = result.content;
                if (alerts.length && this.customPropForm) {
                    this.customPropForm.uploadFiles(alerts[0].id).then(() => {
                        this.updating = false;
                        this.dialogRef.close(true);
                    }).catch(err => this.error = ErrorUtility.getMessage(err, SAVE_DATA_ERROR));
                } else {
                    this.updating = false;
                    this.dialogRef.close(true);
                }
            }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR));
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR));
    }

    isOverflow(el: HTMLElement): boolean {
        var isOverflowing = el.clientWidth < el.scrollWidth
            || el.clientHeight < el.scrollHeight;
        return isOverflowing;
    }

    close(): void {
        this.dialogRef.close();
    }

    private openConfirmDialog(state: ActionState): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.panelClass = state == ActionState.DISCARDED ? "discard-action-confirm-dialog" : "mark-as-done-action-confirm-dialog";
        dialogConfig.autoFocus = false;
        dialogConfig.data = {
            title: state == ActionState.DISCARDED ? "discardActionMessage" : "markAsDoneActionMessage",
            message: state == ActionState.DISCARDED ? "discardActionInfoMessage" : "markAsDoneActionInfoMessage"
        }
        this.dialog.open(ConfirmDialog, dialogConfig).afterClosed().pipe(take(1)).subscribe(result => {
            if (result) {
                state == ActionState.DONE ? this.confirm({ state: ActionState.DONE }) : this.discard();
            }
        });
    }

    private getMaintenanceBody(): any {
        let body = { state: ActionState.DONE };
        if (this.form) {
            const rawValues = this.form.form.getRawValue();
            body['maintenanceNotes'] = rawValues.maintenanceNotes;
            let properties = null;
            if (this.customPropForm) {
                properties = this.customPropForm.getProperties();
            }
            body['maintenanceProperties'] = properties;
        }
        return body;
    }

    private getMaintenanceEvents(): Promise<PagedList<Alert>> {
        let params = new HttpParams();
        params = params.set('page', 0);
        params = params.set('size', 1);
        params = params.set('thingDefinitionId', this.actionStatus.thingDefinitionId);
        params = params.set('alertDefinitionId', this.actionStatus.actionDefinition.doneEventDefinitionId);
        params = params.set('thingId', this.actionStatus.thingId);
        return firstValueFrom(this.httpService.get<PagedList<Alert>>(USER_HISTORICAL_EVENTS_V2, params));
    }

    isPropertyFormInvalid(): boolean {
        if (this.form && this.customPropForm) {
            return !this.customPropForm.checkForm();
        }
        return false;
    }

    private setDefaultMaintenanceNotes(): void {
        if (this.sparePartDefinitionMap) {
            this.defaultMaintenanceNotes = "Replaced spare parts:\n";
            Object.keys(this.sparePartDefinitionMap).forEach(key => {
                this.defaultMaintenanceNotes += `• ${this.sparePartDefinitionMap[key].quantity}x ${this.sparePartDefinitionMap[key].sparePartDefinition.name} [${this.sparePartDefinitionMap[key].sparePartDefinition.code}]\n`;
            });
        }
    }

}