import { Component, Input } from "@angular/core";

@Component({
    selector: 'subscription-set-payment-details',
    template: require('./subscription-set-payment-details.component.html'),
    styles: [`
        .subscription-details-box {
            border: 1px solid #BDBDBD;
            font-size: 14px;
        }
        .subscription-details-box-row {
            border-bottom: 1px solid #BDBDBD;
            padding: 10px;
            gap: 5px;
        }
        .subscription-details-box-row:last-of-type {
            border-bottom: none;
        }
        .subscription-details-box ::ng-deep .mat-expansion-panel-body {
            padding: 0;
        }
        .subscription-details-box mat-expansion-panel-header {
            padding: 2px 10px;
            height: 44px;
        }
        .subscription-details-box mat-expansion-panel-header.mat-expanded {
            border-bottom: 1px solid #BDBDBD;
        }    
    `]
})
export class SubscriptionSetPaymentDetailsComponent {

    @Input() detailRows: SubscriptionSetPaymentDetails[];

}

export class SubscriptionSetPaymentDetails {
    operation: string; 
    category: string; 
    quantity: number; 
    amount: number;
}