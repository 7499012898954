import { Component, forwardRef, Inject, OnInit } from "@angular/core";
import { DateRange } from "@angular/material/datepicker";
import { ActionPriority, ActionState, ActionTopic } from "../../../model";
import { ActionDefinitionService } from "../../../service/action-definition.service";
import { CustomLabelService } from "../../../service/custom-label.service";
import { CustomPropertyService } from "../../../service/custom-property.service";
import { DateRangeService } from "../../../service/date-range.service";
import { CapitalizePipe } from "../../pipe";
import { SearchFieldService } from "../search-field.service";
import { AbstractPropertySearchInputComponent } from "../search-input/abstract-property-search-input.component";

@Component({
    selector: 'actions-property-search-input',
    template: require('./actions-property-search-input.component.html'),
    providers: [SearchFieldService]
})
export class ActionsPropertySearchInputComponent extends AbstractPropertySearchInputComponent implements OnInit {

    constructor(
        @Inject(forwardRef(() => CustomPropertyService)) customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => CustomLabelService)) customLabelService: CustomLabelService,
        @Inject(forwardRef(() => ActionDefinitionService)) private actionDefinitionService: ActionDefinitionService,
        @Inject(forwardRef(() => SearchFieldService)) protected searchFieldService: SearchFieldService,
        @Inject(forwardRef(() => CapitalizePipe)) protected capitalizePipe: CapitalizePipe,
        @Inject(forwardRef(() => DateRangeService)) private dateRangeService: DateRangeService
    ) {
        super(customPropertyService, customLabelService, searchFieldService);
    }

    initConfiguration(): void {
        if (this.property?.indexOf('properties.') > -1 || this.defaultProperties.some(prop => prop.name == this.property)) {
            // MORE FILTERS NOT SUPPORTED
        } else {
            this.useDefaultProperty()
        }
    }

    protected handleDefaultProperty(): void {
        if (this.property == "actionDefinitions") {
            this.handleActionDefinitions();
        } else if (this.property == "statuses") {
            this.handleStatuses();
        } else if (this.property == "priorities") {
            this.handlePriorities();
        } else if (this.property == "period") {
            this.handlePeriod();
        } else if (this.property == "topics") {
            this.handleTopics();
        }
    }

    private handleActionDefinitions(): void {
        this.actionDefinitionService.getAllActionDefinition().then(alertDefs => {
            const actionDefinitionTypes = alertDefs.map(def => { return { value: def.id, label: def.name } });
            if (actionDefinitionTypes?.length) {
                this.advancedSearchConfiguration = [{ name: 'actionDefinitions', placeholder: "All Action Definitions", type: 'STRING', selectionMode: 'MAT_SELECTION', values: actionDefinitionTypes, value: this.searchFieldService.getValueFromFieldsValues('actionDefinitions', this.savedFieldsValues), multipleSelection: true, hideLabel: true }];
            }
        }).catch(err => console.log(err));
    }

    private handleStatuses(): void {
        const statuses: { value: string, label: string }[] = Object.values(ActionState).filter(s => s != ActionState.TODO).map(t => { return { label: this.capitalizePipe.transform(t), value: t } });
        this.advancedSearchConfiguration = [{ name: 'statuses', placeholder: "All Statuses", type: 'STRING', selectionMode: 'MAT_SELECTION', values: statuses, value: this.searchFieldService.getValueFromFieldsValues('statuses', this.savedFieldsValues), multipleSelection: true, hideLabel: true }];
    }

    private handleTopics(): void {
        const topics: { value: string, label: string }[] = Object.values(ActionTopic).map(t => { return { label: this.capitalizePipe.transform(t), value: t } });
        this.advancedSearchConfiguration = [{ name: 'topics', placeholder: "All Topics", type: 'STRING', selectionMode: 'MAT_SELECTION', values: topics, value: this.searchFieldService.getValueFromFieldsValues('topics', this.savedFieldsValues), multipleSelection: true, hideLabel: true }];
    }

    private handlePriorities(): void {
        const priorities: { value: string, label: string }[] = Object.values(ActionPriority).map(t => { return { label: this.capitalizePipe.transform(t), value: t } });
        this.advancedSearchConfiguration = [{ name: 'priorities', placeholder: "All Priorities", type: 'STRING', selectionMode: 'MAT_SELECTION', values: priorities, value: this.searchFieldService.getValueFromFieldsValues('priorities', this.savedFieldsValues), multipleSelection: true, hideLabel: true }];
    }

    private handlePeriod(): void {
        this.advancedSearchConfiguration = [{ name: 'period', type: 'PERIOD', value: this.getDateRange(), hideLabel: true }];
    }

    protected handleValue(body: any, isEncoded: boolean): any {
        if (this.property == 'period') {
            const rangeValue: { range: DateRange<moment.Moment>, rangeName: string } = body['period'];
            const range: DateRange<moment.Moment> = rangeValue?.rangeName ? this.dateRangeService.getCustomDateRangeByName(rangeValue.rangeName)?.range : rangeValue?.range;
            delete body['period'];
            body['rangeName'] = rangeValue?.rangeName;
            body['startTimestamp'] = range?.start?.valueOf();
            body['endTimestamp'] = range?.end?.valueOf();
        }
        return body;
    }

}