import { AfterViewInit, Component, ContentChild, forwardRef, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ErrorMessages } from "../../common/constants";
import { StatisticItem, Thing } from "../../model";
import { AbstractExportContextService } from "../../service/abstract-export-context.service";
import { PeriodVariable } from "../../service/date-range.service";
import { FieldService } from "../../service/field.service";
import { StatisticService } from '../../service/statistic.service';
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";
import { StatisticComponent } from "../../shared/component";
import { LoaderPipe } from "../../shared/pipe";
import { HttpUtility } from "../../utility";
import { ErrorUtility } from "../../utility/error-utility";

@Component({
    selector: 'table-widget',
    template: require('./table-widget.component.html'),
    styles: [require('./table-widget.component.css')]
})
export class TableWidgetComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() title: string;

    @Input() filterEnabled: boolean;

    @Input() exportEnabled: boolean;

    @Input() queryFieldRef: string;

    @Input() categoryFilter: string | Function;

    @Input() valueFilter: string | Function;

    @Input() filterPeriods: string[];

    @Input() exportFileName: string;

    @Input() description: string;

    @ContentChild(StatisticComponent) private statisticComponent: StatisticComponent;

    error: string;
    loaded: boolean = false;
    dataSource: any[] = [];
    displayedColumns: { name: string, label: string }[] = [];
    columns: string[];
    hasPeriodGroupBy: boolean;
    isWithoutGroupBy: boolean;
    subscriberId: string;

    private tableGroupByHeaders: { name: string, label: string }[] = [];
    private statisticSubscription: { fieldsName: string[], subscriberId: string };
    private thing: Thing;
    private exportVisibilitySubscription: Subscription;
    private fieldServiceSubscription: Subscription;
    private queryFieldRefTimeoutId: any;

    static nextId = 0;

    constructor(
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => StatisticService)) private statisticService: StatisticService,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe,
        @Inject(forwardRef(() => AbstractExportContextService)) private exportService: AbstractExportContextService
    ) { }

    ngOnInit(): void {
        this.subscriberId = 'table-widget-' + TableWidgetComponent.nextId++;
        if (this.exportEnabled) {
            this.subscribeToExportServices();
        }
    }

    ngOnDestroy(): void {
        if (this.statisticSubscription) {
            this.fieldService.unsubscribeFromFields(this.statisticSubscription.fieldsName);
        }
        this.exportService.unsubscribeFromExport(this.subscriberId);
        if (this.exportVisibilitySubscription) {
            this.exportVisibilitySubscription.unsubscribe();
        }
        if (this.fieldServiceSubscription) {
            this.fieldServiceSubscription.unsubscribe();
        }
        this.clearQueryFieldRefTimeout();
    }

    ngAfterViewInit(): void {
        if (this.statisticComponent) {
            this.thing = this.thingContextService.getCurrentThing();
            if (this.filterEnabled) {
                this.statisticComponent.startDateFieldRef = this.subscriberId + '-fromDate';
                this.statisticComponent.endDateFieldRef = this.subscriberId + '-toDate';
                this.statisticComponent.periodRef = null;
            } else if (this.statisticComponent.periodRef) {
                this.statisticComponent.startDateFieldRef = null;
                this.statisticComponent.endDateFieldRef = null;
            }
            this.fieldServiceSubscription = this.fieldService.subscribeToFields([this.statisticComponent.startDateFieldRef, this.statisticComponent.endDateFieldRef, this.queryFieldRef, this.statisticComponent.periodRef]).subscribe(fieldsMap => {
                this.clearQueryFieldRefTimeout();
                this.queryFieldRefTimeoutId = setTimeout(() => {
                    this.processStatisticValues(fieldsMap);
                }, 5000);
                if (!this.queryFieldRef || fieldsMap[this.queryFieldRef] != null) {
                    this.clearQueryFieldRefTimeout();
                    this.processStatisticValues(fieldsMap);
                }
            });

            this.statisticSubscription = {
                fieldsName: [this.statisticComponent.startDateFieldRef, this.statisticComponent.endDateFieldRef, this.queryFieldRef, this.statisticComponent.periodRef],
                subscriberId: this.subscriberId
            };

        } else {
            this.loaded = true;
        }
    }

    private processStatisticValues(fieldsMap: any): void {
        this.statisticService.getStatisticValue(this.statisticComponent, fieldsMap, this.thing, fieldsMap[this.queryFieldRef]).then(value => {
            this.error = null;
            this.handleStatisticItem(value);
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
            this.loaded = true;
        });
    }

    handleStatisticItem(statisticItems: StatisticItem[]): void {
        this.displayedColumns = [];
        this.dataSource = [];
        this.tableGroupByHeaders = [];
        this.hasPeriodGroupBy = this.statisticComponent.groupBy ? this.statisticComponent.groupBy.some(el => StatisticService.PERIOD_GROUP_BY_LIST.find(period => period == el) != null) : false;

        if (statisticItems && statisticItems.length > 0) {
            this.buildDataSource(this.statisticService.sortStatisticItems(statisticItems, this.hasPeriodGroupBy, this.statisticComponent), this.statisticComponent.filter);
            this.buildDisplayedColumns();
        }

        this.loaded = true;
    }

    private buildDataSource(statisticItems: StatisticItem[], filter: string | Function): void {
        if (statisticItems.length == 1 && statisticItems[0].category == "Result") {
            /* Single value no groupBy */
            this.isWithoutGroupBy = true;
            let valueItem = this.loaderPipe.transform(statisticItems[0].value, filter, true);
            this.dataSource.push({ category: this.statisticComponent.label || this.statisticService.getStatisticLabel(this.statisticComponent), count: valueItem });
        } else {
            this.isWithoutGroupBy = false;
            statisticItems.forEach(statisticItem => {
                let tableRowObject = {};
                tableRowObject['category'] = statisticItem.category;
                if (statisticItem.value instanceof Array) {
                    /* Double group-by */
                    statisticItem.value.forEach(item => {
                        if (!this.tableGroupByHeaders.find(el => el.name == item.category)) {
                            this.tableGroupByHeaders.push({ name: item.category, label: item.category });
                        }
                        let valueItem = this.loaderPipe.transform(item.value, filter, true);
                        tableRowObject[item.category] = valueItem;
                    });
                } else {
                    /* Single group-by */
                    let valueItem = this.loaderPipe.transform(statisticItem.value, filter, true);
                    tableRowObject['count'] = valueItem;
                }
                this.dataSource.push(tableRowObject);
            });
        }
    }

    private buildDisplayedColumns(): void {
        if (this.isWithoutGroupBy) {
            /* Single value no groupBy */
            this.displayedColumns.push({ name: "category", label: "" });
            this.displayedColumns.push({ name: "count", label: "" });
        } else if (this.tableGroupByHeaders.length > 0) {
            /* Double groupBy */
            this.displayedColumns.push({ name: "category", label: "" });
            this.displayedColumns = this.displayedColumns.concat(this.tableGroupByHeaders.sort((a, b) => (a.name > b.name) ? 1 : -1));
        } else {
            /* Single groupBy */
            this.displayedColumns.push({ name: "category", label: "" });
            this.displayedColumns.push({ name: "count", label: "Count" });
        }
        this.columns = this.displayedColumns.map(el => { return el.name });
    }

    export(): void {
        let exportData = "";
        if (this.statisticComponent && this.dataSource.length > 0) {
            if (this.isWithoutGroupBy) {
                exportData = (this.statisticComponent.label || this.statisticComponent.name) + "," + (this.dataSource[0]['count']);
            } else {
                const columnNames: string[] = this.displayedColumns.map(col => { return col.label });
                exportData = exportData.concat(columnNames.join(','), '\n');
                this.dataSource.forEach(el => {
                    let rowData = [];
                    this.displayedColumns.forEach(displayCol => {
                        rowData.push(el[displayCol.name] ? el[displayCol.name] : "");
                    });
                    exportData = exportData.concat(rowData.join(","), "\n");
                });
            }
        }
        let blob = new Blob([exportData]);
        let startDate: number = null;
        let endDate: number = null;
        if (this.statisticComponent.periodRef) {
            const periodVariable: PeriodVariable = this.fieldService.getValue(this.statisticComponent.periodRef);
            if (periodVariable && periodVariable.start) {
                startDate = periodVariable.start;
            }
            if (periodVariable && periodVariable.end) {
                endDate = periodVariable.end;
            }
        } else {
            if (this.statisticComponent.startDateFieldRef) {
                startDate = this.fieldService.getValue(this.statisticComponent.startDateFieldRef);
            }
            if (this.statisticComponent.endDateFieldRef) {
                endDate = this.fieldService.getValue(this.statisticComponent.endDateFieldRef);
            }
        }
        this.httpUtility.wrapFileAndDownload({ file: blob, fileName: (this.exportService.resolveExportFileNamePlaceholders(this.exportFileName, startDate, endDate) || (this.title || "table-widget") + ".csv") });
    }

    private subscribeToExportServices(): void {
        this.exportService.subscribeToExport(this.subscriberId, this.title || "Table Widget").subscribe(() => this.export());
        this.exportVisibilitySubscription = this.exportService.getIsExportButtonPresent().subscribe(isExportButtonPresent => {
            this.exportEnabled = !isExportButtonPresent;
        });
    }

    private clearQueryFieldRefTimeout(): void {
        if (this.queryFieldRefTimeoutId) {
            clearTimeout(this.queryFieldRefTimeoutId);
            this.queryFieldRefTimeoutId = null;
        }
    }

}