import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from "@angular/core";
import { firstValueFrom } from 'rxjs';
import { ALERT_DEFINITION_REMEDIES, REMEDY_SEND_EMAIL, USERS } from "../../common/endpoints";
import { Alert, AlertDefinitionRemedy, Metric, Thing, User } from "../../model";
import { DataService } from '../../service/data.service';
import { HttpService } from '../../service/http.service';
import { MetricService } from '../../service/metric.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { LoaderPipe } from '../../shared/pipe';

@Injectable()
export class ThingTroubleshootingWidgetService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private http: HttpService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => MetricService)) private metricService: MetricService,
        @Inject(forwardRef(() => DataService)) private dataService: DataService,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe
    ) { }

    getRemediesByAlertDefinitionId(id: string): Promise<AlertDefinitionRemedy[]> {
        return firstValueFrom(this.http.get<AlertDefinitionRemedy[]>(ALERT_DEFINITION_REMEDIES.replace('{id}', id)))
            .catch(() => null);
    }

    getUsers(): Promise<User[]> {
        let users = [];
        return this.getCustomerUsers().then(customerUsers => {
            users = users.concat(customerUsers);
            return this.getLocationUsers().then(locationUsers => {
                users = users.concat(locationUsers);
                return users;
            });
        });
    }

    private getCustomerUsers(): Promise<User[]> {
        const customer = this.contextService.getCurrentCustomer();
        const params = new HttpParams().set('customerId', customer.id);
        return firstValueFrom(this.http.get<User[]>(USERS, params));
    }

    private getLocationUsers(): Promise<User[]> {
        const location = this.contextService.getCurrentLocation();
        const params = new HttpParams().set('locationId', location.id);
        return firstValueFrom(this.http.get<User[]>(USERS, params));
    }

    sendEmail(thing: Thing, remedyId: string, email: string): Promise<void> {
        const params = new HttpParams().set('thingId', thing.id).set('email', encodeURIComponent(email));
        return firstValueFrom(this.http.post<void>(REMEDY_SEND_EMAIL.replace('{id}', remedyId), null, params));
    }

    replaceRemedyPlaceholders(text: string, alert: Alert): Promise<string> {
        if (text) {
            const placeholders = text.match(/(\$\{.+?\})/g);
            if (placeholders) {
                let promises = [];
                placeholders.forEach(placeholder => {
                    promises.push(this.getPlaceholderValue(placeholder, alert).then(value => {
                        text = text.replace(placeholder, value || '');
                    }).catch(() => text = text.replace(placeholder, '')));
                });
                return Promise.all(promises).then(() => {
                    return text;
                });
            } else {
                return Promise.resolve(text);
            }
        } else {
            return Promise.resolve(null);
        }
    }

    private getPlaceholderValue(placeholder: string, alert: Alert): Promise<string> {
        placeholder = placeholder.substring(2, placeholder.length - 1);
        let filter = null;
        let placeholderParts = [];
        const filterIndex = placeholder.indexOf('|')
        if (filterIndex > -1) {
            filter = placeholder.substring(filterIndex + 1);
            placeholderParts = placeholder.substring(0, filterIndex).split('.');
        } else {
            placeholderParts = placeholder.split('.');
        }
        switch (placeholderParts[0]) {
            case 'metrics':
                const metricName = placeholderParts[1];
                return this.metricService.getMetricByName(metricName).then(m => {
                    if (!m) {
                        return Promise.resolve(null);
                    }
                    return this.getMetricPlaceholderValue(placeholderParts, m, alert).then(value => {
                        return filter ? this.loaderPipe.transform(value, filter, true, { metric: m }) : value;
                    });
                }).catch(() => Promise.resolve(null));
            default:
                return Promise.resolve(null);
        }
    }

    private getMetricPlaceholderValue(placeholderParts: string[], metric: Metric, alert: Alert): Promise<string> {
        if (placeholderParts.length < 3) {
            return Promise.resolve(null);
        }
        const metricName = placeholderParts[1];
        const property = placeholderParts[2];
        if (property == 'value') {
            let endTimestamp;
            if (placeholderParts.length == 4 && placeholderParts[3] == 'INITIAL') {
                endTimestamp = alert.startTimestamp;
            } else {
                endTimestamp = alert.endTimestamp || new Date().getTime();
            }
            const params = new HttpParams().set('endDate', endTimestamp);
            return this.dataService.getLastValueByThingIdAndMetricName(alert.thing.id, metricName, params).then(v => {
                return v?.value;
            });
        } else {
            return Promise.resolve(metric ? metric[property] : null);
        }
    }
}