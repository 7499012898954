import { Component, forwardRef, Inject, Input, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '../../../service/app.service';
import { FieldService } from '../../../service/field.service';
import { ActionAdvancedSearchComponent } from '../../advanced-search/action-advanced-search/action-advanced-search.component';
import { AbstractSearchFieldComponent } from '../abstract-search-field.component';
import { SearchFieldService } from '../search-field.service';
import { ActionsPropertySearchInputComponent } from './actions-property-search-input.component';

@Component({
    selector: 'actions-search-field',
    template: require('./actions-search-field.component.html'),
    styles: [require('../search-field.component.css')],
})
export class ActionsSearchFieldComponent extends AbstractSearchFieldComponent {

    @Input() isHistorical: boolean;

    @ViewChild(ActionAdvancedSearchComponent) actionAdvancedSearch: ActionAdvancedSearchComponent;

    @ViewChildren(ActionsPropertySearchInputComponent) private actionsPropertySearchInputs: QueryList<ActionsPropertySearchInputComponent>;

    constructor(
        @Inject(forwardRef(() => FieldService)) fieldService: FieldService,
        @Inject(forwardRef(() => SearchFieldService)) searchFieldService: SearchFieldService,
        @Inject(forwardRef(() => MatDialog)) matDialog: MatDialog,
        @Inject(forwardRef(() => ViewContainerRef)) vcRef: ViewContainerRef,
        @Inject(forwardRef(() => AppService)) appService: AppService
    ) {
        super(fieldService, searchFieldService, matDialog, vcRef, appService);
    }

    openAddPropertiesDialog(advancedSearchProperties: { name: string, label: string }[]): void {
    }

    closeAddPropertiesDialog(updatedSearchFields: string[]): void {
    }

    performClear(): void {
        this.actionAdvancedSearch?.clearAll();
        this.clearInputs();
    }

    protected updateAdvancedSearch(fieldsToSaveBody: any): void {
        this.actionAdvancedSearch?.updateLocalStorage(fieldsToSaveBody);
        this.actionAdvancedSearch?.initAllConfigurations();
    }

    protected getEncodedRawValues(): void {
        let body: any = {};
        this.actionsPropertySearchInputs?.forEach(property => Object.assign(body, property.getFormValue(true)));
        return body;
    }

    protected getRawValues() {
        let body: any = {};
        this.actionsPropertySearchInputs?.forEach(property => Object.assign(body, property.getFormValue(false)));
        return body;
    }

    protected refreshInputConfigurations(): void {
        this.actionsPropertySearchInputs.forEach(input => {
            input.refreshConfiguration();
        });
    }
}