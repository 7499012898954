import { Directive, EventEmitter, forwardRef, Inject, Input, OnInit, Output } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { AuthenticationService } from "../../service/authentication.service";

@Directive()
export abstract class ListPaginator implements OnInit {

    @Input() length: number;

    @Input() pageSize: number;

    @Input() pageIndex: number;

    @Output() pageChangedAction = new EventEmitter();

    protected matPaginatorClass: string;

    protected abstract updatePaginatorClass(): void;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) protected authenticationService: AuthenticationService
    ) { }

    ngOnInit(): void {
        this.updatePaginatorClass();
    }

    changePage(pageEvent: PageEvent): void {
        this.pageChangedAction.emit(pageEvent);
    }
}