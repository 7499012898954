import { Directive, forwardRef, Inject, Input } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { CustomDateRange, DateRangeName, DateRangeService } from '../../../service/date-range.service';
import { PeriodFieldSelectionStyle } from '../period-field/period-field-v2.component';

@Directive()
export class PreselectedRangeComponent {

    @Input() defaultPeriodValue: DateRangeName;

    @Input() filterPeriods: string[];

    @Input() periodSelectionStyle: PeriodFieldSelectionStyle = PeriodFieldSelectionStyle.FLATTEN;

    allowedPeriods: CustomDateRange[];

    constructor(@Inject(forwardRef(() => DateRangeService)) protected dateRangeService: DateRangeService) {
        this.allowedPeriods = this.dateRangeService.getAllDateRanges();
    }

    getPeriod(): any {
        let range = this.allowedPeriods.find(el => el.name == this.defaultPeriodValue);
        return range;
    }

    protected getStartDate(range: DateRange<Moment>) {
        return range && range.start.isValid() ? range.start.valueOf() + "" : null;
    }

    protected getEndDate(range: DateRange<Moment>) {
        return range && range.end.isValid() ? range.end.valueOf() + "" : null;
    }
}