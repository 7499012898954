import { AfterContentInit, Component, ContentChild, ContentChildren, forwardRef, Inject, Input, OnInit, QueryList, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { take } from 'rxjs';
import { PropertyInfo } from '../../common/properties';
import { ActionState, ActionStatus, ActivationType, Thing } from '../../model';
import { AbstractExportContextService } from '../../service/abstract-export-context.service';
import { ActionContextService } from '../../service/action-context.service';
import { AppService } from '../../service/app.service';
import { AuthenticationService } from '../../service/authentication.service';
import { FieldService } from '../../service/field.service';
import { NavigationService } from '../../service/navigation.service';
import { ThingContextService } from '../../service/thing-context.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { ConfirmDialog } from '../../shared/confirm-dialog/confirm-dialog.component';
import { ButtonActionValue, CustomTableService } from '../../shared/custom-table';
import { ClickOnRowBehaviour, ListWidgetV2Component } from '../../shared/list-widget-v2/list-widget-v2.components';
import { DatetimeFormatterPipe, LocalizationPipe } from '../../shared/pipe';
import { SearchFieldComponent, SearchFieldInput, SearchFieldInputType } from '../../shared/search-field/search-field.component';
import { COMPONENT_DEFINITION_REF } from "../../shared/utility/component-definition-token";
import { ErrorUtility } from '../../utility/error-utility';
import { ActionInfoDialogComponent } from '../action-info-dialog/action-info-dialog.component';
import { ActionListWidgetReschedulingDialogComponent } from '../action-list/action-list-widget-rescheduling-dialog.component';
import { ThingListWidgetV2Service } from '../thing-list/thing-list-widget-v2.service';
import { ActionListWidgetV2Service } from './action-list-widget-v2.service';

@Component({
    selector: 'action-list-widget-v2',
    template: require('./action-list-widget-v2.component.html'),
    styles: [require('../../shared/list-widget-v2/list-widget-v2.css'), require('../action-list/action-list-widget.component.css')],
    providers: [ActionListWidgetV2Service, ThingListWidgetV2Service]
})
export class ActionListWidgetV2Component extends ListWidgetV2Component<ActionStatus> implements OnInit, AfterContentInit {

    @Input() searchFields: string[] = ['state', 'actionDefinition.name'];

    @ContentChildren(COMPONENT_DEFINITION_REF) private columnComponents: QueryList<any>;

    @ContentChild(SearchFieldComponent) searchField: SearchFieldComponent;

    @ViewChild(ActionListWidgetReschedulingDialogComponent) reschedulingDialog: ActionListWidgetReschedulingDialogComponent;

    hidden: boolean;
    isActive: boolean = true;
    initCompleted: boolean;
    searchFieldId: string;
    searchFieldInputs: SearchFieldInput[];

    private actionStatuses: ActionStatus[];
    private defaultProperties: { [name: string]: PropertyInfo } = {
        icon: { label: '', path: 'actionDefinition.iconUrl', defaultFilter: null, defaultSorting: null },
        state: { label: 'statusProperty', path: 'state', defaultFilter: null, defaultSorting: null },
        "actionDefinition.name": { label: 'actionDefinitionProperty', path: 'actionDefinition.name', defaultFilter: null, defaultSorting: null },
        startTimestamp: { label: 'startDateProperty', path: 'startTimestamp', defaultFilter: DatetimeFormatterPipe, defaultSorting: null }
    };
    private defaultSearchFieldInputs: SearchFieldInput[] = [
        { type: SearchFieldInputType.PROPERTY, property: 'topics', columnComponents: null },
        { type: SearchFieldInputType.KEY, property: null, columnComponents: null },
        { type: SearchFieldInputType.ADVANCED, property: null, columnComponents: null },
        { type: SearchFieldInputType.CLEAR, property: null, columnComponents: null }
    ];

    constructor(
        @Inject(forwardRef(() => ActionListWidgetV2Service)) private actionListWidgetV2Service: ActionListWidgetV2Service,
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => ActionContextService)) private actionContextService: ActionContextService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => AppService)) appService: AppService,
        @Inject(forwardRef(() => FieldService)) protected fieldService: FieldService,
        @Inject(forwardRef(() => ThingListWidgetV2Service)) private thingListWidgetService: ThingListWidgetV2Service,
        @Inject(forwardRef(() => AbstractExportContextService)) exportService: AbstractExportContextService,
        @Inject(forwardRef(() => ThingContextService)) private thingContextService: ThingContextService,
        @Inject(forwardRef(() => MatDialog)) dialog: MatDialog,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef
    ) {
        super(appService, authenticationService, exportService, null, dialog, null, null);
    }

    ngOnInit() {
        this.searchFieldId = 'action-list-query' + this.getNextId();
        this.checkIsMobile();
        this.checkIfHidden();
        this.readPermission = this.authenticationService.isCustomerUser() || this.authenticationService.isLocationUser()
            || ((this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) && !!this.contextService.getCurrentCustomer());
        this.actionListWidgetV2Service.setActive(this.isActive);
        this.subscribeToPeriodField();
    }

    private checkIfHidden(): void {
        if (!this.thingContextService.getCurrentThing()) {
            if (this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) {
                this.hidden = !this.authenticationService.hasFeature('multipleThingAggregationForOrgPartner');
            } else {
                this.hidden = !this.authenticationService.hasFeature('multipleThingAggregationForCustomer');
            }
        }
    }

    ngAfterContentInit(): void {
        if (this.readPermission) {
            this.getDisplayedColumns([]);
            if (!this.sort) {
                this.sort = this.actionListWidgetV2Service.setDefaultSort(this.displayedColumns);
            }
            setTimeout(() => {      //waits for search field init
                this.getSearchFieldInputs();
                this.initCompleted = true;
            });
        }
    }

    private getSearchFieldInputs(): void {
        this.searchFieldInputs = this.searchField?.inputs || this.defaultSearchFieldInputs;
    }

    private getDisplayedColumns(actionStatuses: ActionStatus[]): void {
        const components = this.columnComponents.toArray().filter(col => !(col instanceof SearchFieldComponent));
        this.displayedColumns = this.actionListWidgetV2Service.getVisibleColumns(components, this.defaultProperties, 'ActionStatus');
        let iconValueMapDone = {
            "_default": {
                isFontAwesome: false,
                customIconClass: 'material-icons action-mark-as-done-icon text-primary',
                customIconHtml: 'check_circle'
            }
        }
        let iconValueMapDiscard = {
            "_default": {
                isFontAwesome: false,
                customIconClass: 'material-icons action-discard-icon text-danger',
                customIconHtml: 'cancel'
            }
        }
        let iconValueMapReschedule = {
            "_default": {
                isFontAwesome: false,
                customIconClass: 'material-icons action-reschedule-icon text-default',
                customIconHtml: 'next_plan'
            }
        }
        this.displayedColumns.push(
            CustomTableService.newIconColumn('complete', '', 'showComplete', iconValueMapDone).withVisiblePath('showComplete').withStyle({ '_any': { 'padding-top': '4px', 'width': '1%' } }).withColumnClass('no-print')
        );
        this.displayedColumns.push(
            CustomTableService.newIconColumn('discard', '', 'showDiscard', iconValueMapDiscard).withVisiblePath('showDiscard').withStyle({ '_any': { 'padding-top': '4px', 'width': '1%' } }).withColumnClass('no-print')
        );
        if (actionStatuses?.some(a => a['showReschedule'])) {
            this.displayedColumns.push(
                CustomTableService.newIconColumn('reschedule', '', 'showReschedule', iconValueMapReschedule).withVisiblePath('showReschedule').withStyle({ '_any': { 'padding-top': '4px', 'width': '1%' } }).withColumnClass('no-print')
            );
        }
        if (this.clickOnRowBehaviour == ClickOnRowBehaviour.OPEN_CONTEXT_OBJECT) {
            let iconValueMapInfo = {
                "_default": {
                    isFontAwesome: false,
                    customIconClass: 'material-icons action-info-icon text-default',
                    customIconHtml: 'info'
                }
            }
            this.displayedColumns.push(
                CustomTableService.newIconColumn('info', '', 'showInfo', iconValueMapInfo).withStyle({ '_any': { 'padding-top': '4px', 'width': '1%' } }).withColumnClass('no-print')
            );
        }
    }

    private getActionList(): void {
        this.actionListWidgetV2Service.getPagedList(this.pageIndex, this.pageSize, this.sort, this.searchFields, this.advancedSearchBody).then(pagedList => {
            this.actionStatuses = pagedList.content;
            this.setIconsVisiblity(pagedList.content);
            this.updateRowClass(pagedList.content);
            this.getDisplayedColumns(pagedList.content);
            let things: Thing[] = pagedList.content.filter(as => as.thing).map(as => { return as.thing });
            this.thingListWidgetService.addTags(things, this.contextService.getTagObjects());
            this.updateElementList(pagedList, !this.isActive);
            this.error = null;
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    refreshList(data?: { pageIndex: number, pageSize: number, advancedSearchBody: any, sort: string[] }): void {
        this.loaded = false;
        if (data) {
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;
            this.advancedSearchBody = data.advancedSearchBody;
            this.sort = data.sort;
        }
        this.getActionList();
    }

    private setIconsVisiblity(elements: ActionStatus[]): any {
        elements.forEach(el => {
            if (this.isActive) {
                el['showComplete'] = this.localizationPipe.transform('Mark as Done');
                el['showDiscard'] = this.localizationPipe.transform('Discard');
                el['showReschedule'] = el.actionDefinition?.activationType == ActivationType.PERIOD && el.actionDefinition?.reschedulable ? this.localizationPipe.transform('Reschedule') : null;
            }
            el['showInfo'] = this.localizationPipe.transform('Info');
        });
        return elements;
    }

    execButtonAction(actionValue: ButtonActionValue): void {
        if (actionValue.action == 'complete') {
            const action = this.actionStatuses[actionValue.index];
            if (action.actionDefinition?.doneEventDefinitionId) {
                this.openActionInfoDialog(action);
            } else {
                this.openConfirmDialog(actionValue.index, ActionState.DONE);
            }
        } else if (actionValue.action == 'discard') {
            this.openConfirmDialog(actionValue.index, ActionState.DISCARDED);
        } else if (actionValue.action == 'reschedule') {
            this.reschedulingDialog.open(this.actionStatuses[actionValue.index]);
        } else if (actionValue.action == 'info') {
            this.openActionInfoDialog(this.actionStatuses[actionValue.index]);
        }
    }

    private updateActionState(body: any, actionStatus: ActionStatus): void {
        this.actionListWidgetV2Service.updateAction(actionStatus, body).then(() => {
            this.refreshElementList();
            this.actionContextService.updateRefreshEventSubject(true);
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    goToDetail(status: ActionStatus): void {
        if (status && status.thingId && this.clickOnRowBehaviour == ClickOnRowBehaviour.OPEN_CONTEXT_OBJECT) {
            this.navigationService.goToThingDetailPage(status.thingId);
        } else if (status) {
            this.openActionInfoDialog(status);
        }
    }

    private updateRowClass(elements: ActionStatus[]): ActionStatus[] {
        if (elements?.length > 0) {
            elements.forEach(as => {
                as['rowClass'] = as.actionDefinition?.priority;
            });
        }
        return elements;
    }

    protected subscribeToExportServices(): void {
        // do nothing, export not supported
    }

    rescheduleAction(rescheduleBody: any): void {
        const actionStatusId = rescheduleBody.actionStatusId;
        delete rescheduleBody.actionStatusId;
        this.updateActionState(rescheduleBody, this.actionStatuses.find(el => el.id == actionStatusId));
    }

    private openConfirmDialog(index: number, state: ActionState): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.panelClass = state == ActionState.DISCARDED ? "discard-action-confirm-dialog" : "mark-as-done-action-confirm-dialog";
        dialogConfig.autoFocus = false;
        dialogConfig.data = {
            title: state == ActionState.DISCARDED ? "discardActionMessage" : "markAsDoneActionMessage",
            message: state == ActionState.DISCARDED ? "discardActionInfoMessage" : "markAsDoneActionInfoMessage"
        }
        this.dialog.open(ConfirmDialog, dialogConfig).afterClosed().pipe(take(1)).subscribe(result => {
            if (result) {
                this.updateActionState({ state: state }, this.actionStatuses[index]);
            }
        });
    }

    private openActionInfoDialog(actionStatus: ActionStatus): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.maxWidth = '528px';
        dialogConfig.autoFocus = false;
        dialogConfig.data = { actionStatus: actionStatus, isHistorical: !this.isActive };
        dialogConfig.viewContainerRef = this.vcRef;
        this.dialog.open(ActionInfoDialogComponent, dialogConfig).afterClosed().pipe(take(1)).subscribe(refresh => {
            if (refresh) {
                this.refreshElementList();
                this.actionContextService.updateRefreshEventSubject(true);
            }
        });
    }

    changeStatus(newStatus: boolean): void {
        this.isActive = newStatus;
        this.actionListWidgetV2Service.setActive(this.isActive);
        this.sort = [];
        this.elements = [];
        this.selectedElements = [];
        this.allElementsSelected = false;
        this.loaded = false;
        this.pageIndex = 0;
        this.initCompleted = false;
        setTimeout(() => {
            this.initCompleted = true;
        }, 10);
    }
}