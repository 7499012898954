import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import * as moment from 'moment';
import { ActionPriority, ActionState, ActionTopic } from '../../../model';
import { ActionDefinitionService } from '../../../service/action-definition.service';
import { DateRangeService } from '../../../service/date-range.service';
import { FieldService } from '../../../service/field.service';
import { CapitalizePipe, LocalizationPipe } from '../../../shared/pipe';
import { AbstractAdvancedSearchComponent } from '../abstract-advanced-search.component';

@Component({
    selector: 'action-advanced-search',
    template: require('./action-advanced-search.component.html'),
    styles: [require('../thing-advanced-search/thing-advanced-search.component.css')],
})
export class ActionAdvancedSearchComponent extends AbstractAdvancedSearchComponent implements OnInit {

    @Input() isHistorical: boolean;

    @Input() periodDisabled: boolean;

    private searchDataInitialized: boolean;
    private actionDefinitionTypes: { value: string, label: string }[];
    private topics: { value: string, label: string }[] = Object.values(ActionTopic).map(t => { return { label: this.capitalizePipe.transform(t), value: t } });
    private statuses: { value: string, label: string }[] = Object.values(ActionState).filter(s => s != ActionState.TODO).map(t => { return { label: this.capitalizePipe.transform(t), value: t } });
    private priorities: { value: string, label: string }[] = Object.values(ActionPriority).map(t => { return { label: this.capitalizePipe.transform(t), value: t } });

    constructor(
        @Inject(forwardRef(() => CapitalizePipe)) private capitalizePipe: CapitalizePipe,
        @Inject(forwardRef(() => LocalizationPipe)) localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => FieldService)) fieldService: FieldService,
        @Inject(forwardRef(() => ActionDefinitionService)) private actionDefinitionService: ActionDefinitionService,
        @Inject(forwardRef(() => DateRangeService)) private dateRangeService: DateRangeService
    ) { super(localizationPipe, fieldService) }

    ngOnInit(): void {
        this.localStorageKey = this.queryId || 'actionAdvancedSearchFieldsValues';
        this.savedFieldsValues = localStorage.getItem(this.localStorageKey) ? JSON.parse(localStorage.getItem(this.localStorageKey)) : null;
        if (this.query && this.query.length) {
            this.getEncodedQueryFields();
        }
        if (this.queryFieldRef) {
            this.subscribeToQueryFieldRef();
        } else {
            if (this.savedFieldsValues || this.alwaysExpanded) {
                this.showHideAdvancedSearch().then(() => this.waitForAdvancedSearchRenderedAndPerformSearch());
            } else if (this.encodedQueryFields) {
                this.loadData(null, this.encodedQueryFields);
            }
        }
    }

    advancedSearch($event?): void {
        this.simpleSearchKey = null;
        const rawValue = this.advancedSearchEditor.getObjectValue();
        const key = this.advancedSearchBarEditor.getObjectValue()['key'];
        const rangeValue: { range: DateRange<moment.Moment>, rangeName: string } = rawValue['period'];
        const range: DateRange<moment.Moment> = rangeValue?.rangeName ? this.dateRangeService.getCustomDateRangeByName(rangeValue.rangeName)?.range : rangeValue?.range;
        const fields = {
            key: key,
            actionDefinitions: rawValue['actionDefinitions'],
            topics: rawValue['topics'],
            statuses: rawValue['statuses'],
            priorities: rawValue['priorities'],
            startTimestamp: range?.start?.valueOf(),
            endTimestamp: range?.end?.valueOf(),
        };
        let fieldsToSave = {
            key: key,
            actionDefinitions: rawValue['actionDefinitions'],
            topics: rawValue['topics'],
            statuses: rawValue['statuses'],
            priorities: rawValue['priorities']
        };
        if (rangeValue?.rangeName) {
            fieldsToSave['rangeName'] = rangeValue?.rangeName;
        } else {
            fieldsToSave['startTimestamp'] = range?.start?.valueOf();
            fieldsToSave['endTimestamp'] = range?.end?.valueOf();
        }
        let encodedBody = Object.assign({}, fields);
        let fieldsToSaveBody = Object.assign({}, fieldsToSave);
        if (this.query && this.query.length) {
            encodedBody = Object.assign({}, encodedBody, this.encodedQueryFields);
            fieldsToSaveBody = this.removeQueryFields(fieldsToSaveBody);
        }
        this.handleSearchFieldSelectionInputs(encodedBody, fieldsToSaveBody);
        this.updateLocalStorage(fieldsToSaveBody);
        const encodedBodyValues = Object.keys(encodedBody).map(el => encodedBody[el]);
        if (encodedBodyValues.some(el => el != null)) {
            this.loadData(key, encodedBody);
        } else {
            this.loadData();
        }
        if ($event) {
            const eventObject = $event.currentTarget;
            eventObject.blur();
        }
    }

    protected initConfigurations(): Promise<void> {
        let advancedSearchBarConfiguration = [];
        advancedSearchBarConfiguration.push({ name: 'key', type: 'SEARCH', value: this.getValue('key') || this.simpleSearchKey });
        this.advancedSearchBarConfiguration = advancedSearchBarConfiguration;
        return this.getSearchData().then(() => {
            let advancedSearchConfiguration = [];
            if (!this.periodDisabled) {
                advancedSearchConfiguration.push({ name: 'period', 'label': 'periodProperty', type: 'PERIOD', value: this.getDateRange() || { range: null, rangeName: null }, defaultValue: this.isQueryField('startTimestamp') && this.isQueryField('endTimestamp') ? this.getDateRange() : null });
            }
            advancedSearchConfiguration.push({ name: 'topics', label: 'topicsProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: this.topics, value: this.getValue('topics'), multipleSelection: true, disabled: this.isQueryField('topics'), defaultValue: this.isQueryField('topics') ? this.getValue('topics') : null, placeholder: "All Topics" });
            if (this.actionDefinitionTypes.length > 1) {
                advancedSearchConfiguration.push({ name: 'actionDefinitions', label: 'typesProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: this.actionDefinitionTypes, value: this.getValue('actionDefinitions'), multipleSelection: true, disabled: this.isQueryField('actionDefinitions'), defaultValue: this.isQueryField('actionDefinitions') ? this.getValue('actionDefinitions') : null, placeholder: "All Types" });
            }
            if (this.isHistorical) {
                advancedSearchConfiguration.push({ name: 'statuses', label: 'statusesProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: this.statuses, value: this.getValue('statuses'), multipleSelection: true, disabled: this.isQueryField('statuses'), defaultValue: this.isQueryField('statuses') ? this.getValue('statuses') : null, placeholder: "All Statuses" });
            }
            advancedSearchConfiguration.push({ name: 'priorities', label: 'prioritiesProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', values: this.priorities, value: this.getValue('priorities'), multipleSelection: true, disabled: this.isQueryField('priorities'), defaultValue: this.isQueryField('priorities') ? this.getValue('priorities') : null, placeholder: "All Priorities" });
            this.fieldsPerRow = 2;
            this.advancedSearchConfiguration = advancedSearchConfiguration;
        });
    }

    private getSearchData(): Promise<any> {
        if (!this.searchDataInitialized) {
            this.searchDataInitialized = true;
            let promises = [];
            promises.push(this.actionDefinitionService.getAllActionDefinition().then(defs => this.actionDefinitionTypes = defs.map(d => { return { value: d.id, label: d.name } })).catch(() => []));
            return Promise.all(promises);
        }
        return Promise.resolve();
    }

    private getDateRange(): { range: DateRange<moment.Moment>, rangeName: string } {
        const rangeName = this.getValue('rangeName');
        if (rangeName) {
            return { range: null, rangeName: rangeName };
        } else {
            const start = this.getValue('startTimestamp');
            const end = this.getValue('endTimestamp');
            if (start && end) {
                return { range: new DateRange(moment(start), moment(end)), rangeName: null };
            }
        }
        return null;
    }

    getEncodedQueryFields(): void {
        let fields = [];
        this.query.forEach(el => {
            fields[el.property] = el.value;
        });
        this.encodedQueryFields = fields;
    }
}