import { Component, forwardRef, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Subscription } from "rxjs";
import { AlertService } from "../../dashboard-area/shared/alert.service";
import { Alert, AlertDefinitionRemedy, AlertRemedySelection } from "../../model";
import { AuthenticationService } from '../../service/authentication.service';
import { AbstractContextService } from "../../shared/class/abstract-context-service.class";
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";
import { ThingTroubleshootingWidgetService } from "./thing-troubleshooting-widget.service";

@Component({
    selector: 'thing-troubleshooting-widget',
    template: require('./thing-troubleshooting-widget.component.html'),
    providers: [ThingTroubleshootingWidgetService, AlertService]
})
export class ThingTroubleshootingWidgetComponent implements OnInit, OnDestroy {

    @Input() title: string;

    @Input() loadPeriod: string = 'P7D';

    @Input() sendRemedyEnabled: boolean;

    @Input() description: string;

    constructor(
        @Inject(forwardRef(() => ActivatedRoute)) private activatedRoute: ActivatedRoute,
        @Inject(forwardRef(() => AlertService)) private alertService: AlertService,
        @Inject(forwardRef(() => ThingTroubleshootingWidgetService)) private thingTroubleshootingWidgetService: ThingTroubleshootingWidgetService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService
    ) { }

    activeAndHistoricalList: Alert[] = [];
    solvedHistoricalList: Alert[] = [];
    remediesByAlertDef: { [alertDefinitionId: string]: AlertDefinitionRemedy[] } = {};
    showSolved: boolean;
    loading: boolean;
    userEmails: string[];
    activeAndHistoricalGroupByAlertName: { [alertName: string]: Alert[] };
    timezone: string;

    private historicalFields = ['alertDefinitionId', 'alertRemedySelections', 'description', 'duration', 'id', 'name', 'severity', 'category', 'technicalDescription', 'title'];
    private alertDefIds = new Set<string>();
    private historicalStartDate: string;
    private alertSubscription: Subscription;

    ngOnInit() {
        const user = this.authenticationService.getUser();
        this.timezone = user.timezone || 'UTC';
        this.loading = true;
        this.historicalStartDate = moment().subtract(moment.duration(this.loadPeriod)).valueOf() + '';
        this.activatedRoute.data.subscribe(() => {
            const data = this.getContextData();
            this.alertSubscription = this.getActiveAlerts(data).subscribe({
                next: alerts => {
                    if (alerts) {
                        this.loading = true;
                        this.getRemedies(alerts).then(() => {
                            this.getHistoricaAlerts(data).then(historicalAlerts => {
                                this.getRemedies(historicalAlerts).then(() => {
                                    let solved = [];
                                    let unsolved = [];
                                    this.filterSolved(historicalAlerts).forEach(h => {
                                        if (this.isSolved(h.alertRemedySelections)) {
                                            solved.push(h);
                                        } else {
                                            unsolved.push(h);
                                        }
                                    })
                                    const activeAndHistoricalList = (this.filterSolved(alerts).concat(unsolved)).sort(this.compareAlerts);
                                    this.activeAndHistoricalGroupByAlertName = _.groupBy(activeAndHistoricalList, "name");
                                    this.activeAndHistoricalList = [];
                                    Object.keys(this.activeAndHistoricalGroupByAlertName).forEach(key => {
                                        if (this.activeAndHistoricalGroupByAlertName[key] && this.activeAndHistoricalGroupByAlertName[key].length) {
                                            this.activeAndHistoricalList.push(this.activeAndHistoricalGroupByAlertName[key][0]);
                                        }
                                    })
                                    this.solvedHistoricalList = solved.sort(this.compareAlerts);
                                    this.loading = false;
                                });
                            });
                        });
                    }
                },
                error: err => console.error(err)
            });
        });
        this.thingTroubleshootingWidgetService.getUsers().then(users => {
            this.userEmails = users.map(u => u.email);
        }).catch(() => { /* NO AUTH - DO NOTHING */ });
    }

    private getActiveAlerts(data: any): BehaviorSubject<Alert[]> {
        if (data.alert) {
            if (data.alert.endTimestamp) {
                return new BehaviorSubject([])
            }
            return new BehaviorSubject([data.alert])
        }
        return this.alertService.loadActiveAlerts(data);
    }

    private getHistoricaAlerts(data: any): Promise<Alert[]> {
        if (data.alert) {
            if (data.alert.endTimestamp) {
                return Promise.resolve([data.alert]);
            }
            return Promise.resolve([]);
        }
        return this.alertService.loadHistoricalAlert(this.historicalFields, true, data, null, this.historicalStartDate);
    }

    private compareAlerts(a1: Alert, a2: Alert) {
        return a2.startTimestamp - a1.startTimestamp;
    }

    private filterSolved(alerts: Alert[]): Alert[] {
        return alerts.filter(a => this.remediesByAlertDef[a.alertDefinitionId] && this.remediesByAlertDef[a.alertDefinitionId].length);
    }

    private isSolved(alertRemedySelections: AlertRemedySelection[]): boolean {
        if (alertRemedySelections && alertRemedySelections.length) {
            return alertRemedySelections[alertRemedySelections.length - 1] && alertRemedySelections[alertRemedySelections.length - 1].solved;
        } else {
            return false;
        }
    }

    updateSelection(alertIndex: number, alertRemedySelections: AlertRemedySelection[], fromSolved: boolean): void {
        let alert = fromSolved ? this.solvedHistoricalList[alertIndex] : this.activeAndHistoricalList[alertIndex];
        const timestamp = new Date().getTime();
        const userId = this.authenticationService.getUser().id;
        alertRemedySelections.forEach(s => { s.userId = userId; s.timestamp = timestamp; });
        if (fromSolved) {
            this.alertService.updateSelection(alert, alertRemedySelections).then(() => { // move solved/unsolved only historical
                if (alert.endTimestamp && !this.isSolved(alertRemedySelections)) {
                    this.solvedHistoricalList.splice(alertIndex, 1);
                    alert.alertRemedySelections = alertRemedySelections;
                    if (this.activeAndHistoricalGroupByAlertName[alert.name] && this.activeAndHistoricalGroupByAlertName[alert.name].length) {
                        this.activeAndHistoricalGroupByAlertName[alert.name].push(alert);
                    } else {
                        this.activeAndHistoricalGroupByAlertName[alert.name] = [alert];
                        this.activeAndHistoricalList.push(alert);
                    }
                }
            });
        } else {
            let promises = [];
            const alertsByName = _.cloneDeep(this.activeAndHistoricalGroupByAlertName[alert.name]);
            alertsByName.forEach(alert => {
                promises.push(this.alertService.updateSelection(alert, alertRemedySelections));
            })
            Promise.all(promises).then(() => { // move solved/unsolved only historical
                alertsByName.forEach((alert, index) => {
                    if (alert.endTimestamp && this.isSolved(alertRemedySelections)) {
                        if (index == 0) {
                            this.activeAndHistoricalList.splice(alertIndex, 1);
                        }
                        this.activeAndHistoricalGroupByAlertName[alert.name].splice(this.activeAndHistoricalGroupByAlertName[alert.name].indexOf(alert), 1);
                        alert.alertRemedySelections = _.cloneDeep(alertRemedySelections);
                        this.solvedHistoricalList.push(alert);
                    }
                });
            });
        }
    }

    private getRemedies(alerts: Alert[]): Promise<void> {
        alerts.forEach(alert => this.alertDefIds.add(alert.alertDefinitionId));
        let remedyRequests: Promise<AlertDefinitionRemedy[]>[] = [];
        let alertsIdsWithoutRemedies = [];
        this.alertDefIds.forEach(alertDefId => {
            if (!this.remediesByAlertDef[alertDefId]) {
                remedyRequests.push(this.thingTroubleshootingWidgetService.getRemediesByAlertDefinitionId(alertDefId));
                alertsIdsWithoutRemedies.push([alertDefId]);
            }
        });
        return Promise.all(remedyRequests).then(
            remediesResponse => {
                remediesResponse.forEach((r, i) => {
                    this.remediesByAlertDef[alertsIdsWithoutRemedies[i]] = r;
                });
            });
    }

    ngOnDestroy(): void {
        if (this.alertSubscription) {
            this.alertSubscription.unsubscribe();
            this.alertSubscription = null;
        }
    }

    private getContextData(): any {
        if (this.thingContextService.getCurrentAlert()) {
            return { alert: this.thingContextService.getCurrentAlert() };
        } else if (this.thingContextService.getCurrentThing()) {
            return { thing: this.thingContextService.getCurrentThing() };
        } else if (this.contextService.getCurrentLocation()) {
            return { location: this.contextService.getCurrentLocation() };
        } else if (this.contextService.getCurrentCustomer()) {
            return { customer: this.contextService.getCurrentCustomer() };
        } else if (this.contextService.getCurrentPartner()) {
            return { partner: this.contextService.getCurrentPartner() };
        } else {
            return {};
        }
    }
}