import { Component, forwardRef, Inject, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as moment from 'moment';
import { SubscriptionSetPayment } from "../../model";
import { AuthenticationService } from "../../service/authentication.service";
import { SubscriptionSetService } from "../../service/subscription-set.service";
import { SubscriptionSetPaymentDetails } from "./subscription-set-payment-details.component";

@Component({
    selector: 'subscription-set-payment-details-dialog',
    template: require('./subscription-set-payment-details-dialog.component.html'),
    styles: [`
        label {
            margin-bottom: 5px;
        }    
    `]
})
export class SubscriptionSetPaymentDetailsDialogComponent implements OnInit {

    @ViewChild('form') form: NgForm;

    payment: SubscriptionSetPayment;
    isOrganizationUser: boolean;
    detailRows: SubscriptionSetPaymentDetails[] = [];
    timezone: string;
    locale: string;
    paymentMoment: moment.Moment;

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) private dialogRef: MatDialogRef<SubscriptionSetPaymentDetailsDialogComponent>,
        @Inject(forwardRef(() => SubscriptionSetService)) private subscriptionSetService: SubscriptionSetService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(MAT_DIALOG_DATA) data
    ) {  
        this.payment = data.payment; 
        if(this.payment.paymentTimestamp){
            this.paymentMoment = moment(this.payment.paymentTimestamp);
        } 
    }

    ngOnInit(): void {
        this.isOrganizationUser = this.authenticationService.isOrganizationUser();
        for (let item of this.payment.items || []) {
            let detailRow = this.detailRows.find(r => r.category == item.category && r.operation == item.operation);
            if (detailRow) {
                detailRow.amount += item.amount;
                detailRow.quantity++;
            } else {
                this.detailRows.push({ quantity: 1, amount: item.amount, category: item.category, operation: item.operation });
            }
        }
    }

    confirm(): void {
        let body = this.form.form.getRawValue();
        let paymentMoment = body.paymentMoment;
        if(paymentMoment){
            body.paymentTimestamp = paymentMoment.valueOf();
        }
        this.subscriptionSetService.updatePaymentStatus(this.payment.id, body);
        this.dialogRef.close(true);
    }
}