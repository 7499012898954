import { Component, forwardRef, Host, Inject, Input, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ErrorMessages, Permissions } from "../../common/constants";
import { TestOutcome, Thing, ThingTestSession } from "../../model";
import { AuthenticationService } from '../../service/authentication.service';
import { NavigationService } from '../../service/navigation.service';
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";
import { ModalComponent } from "../../shared/component";
import { ButtonActionValue, CustomTableColumn, CustomTableService } from "../../shared/custom-table";
import { ErrorUtility } from "../../utility/error-utility";
import { ThingTestSessionListWidgetService } from "./thing-test-session-list-widget.service";
import { ThingTestSessionOutcomeDialogComponent } from "./thing-test-session-outcome-dialog.component";
import { ThingTestSessionTokenDialogComponent } from "./thing-test-session-token-dialog.component";
import { ThingTestSessionValidationComponent } from "./thing-test-session-validation.component";

@Component({
    selector: 'thing-test-session-list-widget',
    template: require('./thing-test-session-list-widget.component.html'),
    styles: [require('./thing-test-session-list-widget.component.css')],
    providers: [ThingTestSessionListWidgetService]
})
export class ThingTestSessionListWidgetComponent {

    @Input() templateName: string;

    @Input() askForTokenReleaseOnTestStop;

    @ViewChild('confirmDeleteAlert') private confirmDeleteAlert: ModalComponent;

    @ViewChild('tokenDialog') private tokenDialog: ThingTestSessionTokenDialogComponent;

    @ViewChild('outcomeDialog') private outcomeDialog: ThingTestSessionOutcomeDialogComponent;

    @ViewChildren(ThingTestSessionValidationComponent) private validationComponents: QueryList<ThingTestSessionValidationComponent>;

    constructor(
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => ThingTestSessionListWidgetService)) private testListService: ThingTestSessionListWidgetService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService
    ) { }

    loaded: boolean;
    error: string;
    writePermission: boolean;
    thingTestSessions: ThingTestSession[] = [];
    addVisible: boolean;
    dataSource = new MatTableDataSource<ThingTestSession>([]);
    displayedColumns: CustomTableColumn[];

    private testIdToDelete: string;
    private thing: Thing;
    private STOP_BUTTON = 'stop';
    private DELETE_BUTTON = 'delete';
    private DETAILS_BUTTON = 'details';

    ngOnInit() {
        this.thing = this.thingContextService.getCurrentThing();
        if (!this.thing.locationId) {
            this.askForTokenReleaseOnTestStop = false;
        }
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_THING_TEST);
        this.refreshTable();
    }

    refreshTable(): void {
        this.loaded = false;
        this.testListService.getTestSessionsByThingId(this.thing.id).then(testSessions => {
            this.thingTestSessions = testSessions;
            this.addVisible = this.writePermission && this.thingTestSessions.every(t => !!t.stoppedAt);
            this.dataSource = new MatTableDataSource<ThingTestSession>(this.thingTestSessions);
            this.setDisplayedColumns();
            this.loaded = true;
        }).catch(() => this.error = ErrorMessages.GET_DATA_ERROR);
    }

    private setDisplayedColumns(): void {
        this.displayedColumns = [
            CustomTableService.newDatetimeColumn('startedAt', 'startDateProperty', 'startedAt', null, this.authenticationService.getUser().timezone),
            CustomTableService.newPipedColumn('type', 'typeProperty', 'type', 'underscoreRemover'),
            CustomTableService.newTimerColumn('duration', 'testDurationProperty', 'startedAt', 'stoppedAt'),
            CustomTableService.newSimpleColumn('outcome', 'testOutcomeProperty', 'outcome'),
            CustomTableService.newSimpleColumn('userEmail', 'userProperty', 'userEmail')
        ];
        if (!this.addVisible) {
            this.displayedColumns.push(
                CustomTableService.newButtonColumn(this.STOP_BUTTON, '', 'id', 'btn btn-primary btn-stop-test', 'stopButton').withVisiblePath('!stoppedAt').withColumnClass('no-print')
            );
        }
        if (this.templateName) {
            this.displayedColumns.push(
                CustomTableService.newButtonColumn(this.DETAILS_BUTTON, '', 'id', 'btn btn-primary', 'openDetailsPageButton').withIcon(['fas', 'angle-double-right']).withColumnClass('no-print')
            );
        }
        if (this.writePermission) {
            this.displayedColumns.push(
                CustomTableService.newButtonColumn(this.DELETE_BUTTON, '', 'id', 'btn btn-default', 'deleteButton').withIcon(['fas', 'times']).withColumnClass('no-print')
            );
        }
    }

    execButtonAction(actionValue: ButtonActionValue): void {
        switch (actionValue.action) {
            case this.STOP_BUTTON:
                this.openOutcomeDialog(actionValue.value);
                break;
            case this.DELETE_BUTTON:
                this.confirmDelete(actionValue.value);
                break;
            case this.DETAILS_BUTTON:
                this.openDetails(actionValue.value);
        }
    }

    private openOutcomeDialog(id: string): void {
        let isFailing = false;
        if (this.validationComponents) {
            const component = this.validationComponents.find(c => c.thingTestSession.id == id);
            if (component) {
                isFailing = component.isFailing();
            }
        }
        this.outcomeDialog.open(id, isFailing);
    }

    private confirmDelete(id: string) {
        this.testIdToDelete = id;
        this.confirmDeleteAlert.show();
    }

    private openDetails(id: string) {
        this.navigationService.navigateTo(['dashboard/thingTestSessions', id, this.templateName]);
    }

    deleteTestSession(): void {
        this.error = null;
        this.confirmDeleteAlert.hide();
        this.testListService.deleteTestSession(this.testIdToDelete)
            .then(() => this.refreshTable())
            .catch(() => this.error = ErrorMessages.DELETE_DATA_ERROR);
    }

    cancel(): void {
        this.confirmDeleteAlert.hide();
    }

    startTest(): void {
        this.error = null;
        this.startNewTest().then(() => this.refreshTable())
            .catch(err => {
                if (this.isInvalidTokenError(err)) {
                    this.tokenDialog.open();
                } else {
                    this.error = ErrorUtility.getMessage(err);
                }
            });
    }

    private startNewTest(connectionToken?: string): Promise<ThingTestSession> {
        let thingTestSession = new ThingTestSession();
        thingTestSession.thingId = this.thing.id;
        thingTestSession.connectionToken = connectionToken;
        return this.testListService.startTestSession(thingTestSession);
    }

    private isInvalidTokenError(err: any): boolean {
        const message = ErrorUtility.getMessage(err);
        return (err.status == 404 && message == "Invalid connection token") ||
            (err.status == 409 && message == "Token is already used");
    }

    startTestWithConnectionToken(connectionToken: string): void {
        this.startNewTest(connectionToken).then(() => this.refreshTable())
            .catch(err => this.error = ErrorUtility.getMessage(err));
    }

    stopTest(testOutcome: { testId: string, outcome: TestOutcome, note: string, releaseToken: boolean }): void {
        this.error = null;
        let thingTestSession = new ThingTestSession();
        thingTestSession.outcome = testOutcome.outcome;
        thingTestSession.note = testOutcome.note;
        this.testListService.stopTestSession(testOutcome.testId, thingTestSession, testOutcome.releaseToken).then(() => this.refreshTable())
            .catch(err => this.error = ErrorUtility.getMessage(err));
    }

}