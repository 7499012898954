import { ActionDefinition, ActivationPeriod, Customer, Location, Thing, ThingDefinition, User } from ".";

export class ActionStatus {
    public id: string;
    public thingId: string;
    public thing: Thing;
    public thingDefinitionId: string;
    public thingDefinition: ThingDefinition;
    public locationId: string;
    public location: Location;
    public customerId: string;
    public customer: Customer;
    public actionDefinitionId: string;
    public actionDefinition: ActionDefinition;
    public startTimestamp: number;
    public timestamp: number;
    public state: ActionState;
    public userId: string;
    public user: User;
    public lastDoneTimestamp: number;
    public lastDiscardedTimestamp: number;
    public period: ActivationPeriod;
    public periodCount: number;
    public dayOfMonth: number;
    public dayOfWeek: number;
    public dueTimestamp: number;
    public wearStatus: any;
}

export enum ActionState {
    TODO = "TODO",
    DONE = "DONE",
    DISCARDED = "DISCARDED"
}