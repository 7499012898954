import { forwardRef, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { isEmpty } from '../common/helper';
import { Alert, Customer, Location as Loc, Organization, Partner, Thing, UiProfilePage, User, WorkSession } from '../model/index';
import { Rule } from '../model/rule';
import { SparePartDefinition } from '../model/spare-part-definition';
import { Task } from '../model/task';
import { AuthenticationService } from './authentication.service';
import { UiService } from './ui.service';

export class BreadCrumbToken {
    name: string;
    label: string;
    link: any[];
    tabIndex: number;
    tabPage: string;
    styleClass: string;
    isSubTab: boolean
}

@Injectable()
export class BreadcrumbService {

    private subject: BehaviorSubject<BreadCrumbToken[]>;

    private tokens: BreadCrumbToken[];

    private isCustomersPagePresent: boolean;

    private isPartnersPagePresent: boolean;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => UiService)) private uiService: UiService
    ) {
        this.subject = new BehaviorSubject(null);
        this.tokens = [];
        this.checkPages();
    }

    newBuilder(): BreadcrumbService {
        this.tokens = [this.buildToken('home', ['/dashboard'])];
        return this;
    }

    addProfile() {
        this.tokens.push(this.buildToken('profile', null, 'Profile'));
        return this;
    }

    addPrivacyAndTerms(): any {
        this.tokens.push(this.buildToken('privacyAndTerms', null, 'privacyAndTermsLink'));
        return this;
    }

    addCustomPage(page: UiProfilePage) {
        this.tokens.push(this.buildToken(page.name, ['/dashboard', page.urlPath], page.name));
        return this;
    }

    addOrganization() {
        this.tokens.push(this.buildToken('organization', ['/dashboard/organization'], 'organizationLink'));
        return this;
    }

    addOrganizationSubOrganization(org: Organization): BreadcrumbService {
        const name = org.name;
        this.tokens.push(this.buildToken(`${name}`, ['/dashboard/organization', org.id], `${name}`));
        return this;
    }

    addAccount(hosted: boolean = false) {
        this.tokens.push(this.buildToken('account', ['/dashboard/account'], hosted ? 'guestUsersLink' : 'accountLink'));
        return this;
    }

    addGeneralGuest(url: string) {
        this.tokens.push(this.buildToken('customer', [url], 'guestUsersLink'));
        return this;
    }

    addAccountLocation(location: Loc) {
        this.tokens.push(this.buildToken(location.name, ['/dashboard/account/location_details', location.id], location.name));
        return this;
    }

    addAccountLocationUser(user: User) {
        const name = this.printUser(user);
        this.tokens.push(this.buildToken(`${name}`, ['/dashboard/account/location_details', user.locationId, 'users', user.id], `${name}`));
        return this;
    }

    addAccountThing(thing: Thing, underlocation: boolean) {
        if (underlocation) {
            this.tokens.push(this.buildToken(thing.name, ['/dashboard/account/location_details', thing.location.id, 'thing_details', thing.id], thing.name));
        } else {
            this.tokens.push(this.buildToken(thing.name, ['/dashboard/account/thing_details', thing.id], thing.name));
        }
        return this;
    }

    addAccountRule(rule: Rule, underlocation: boolean) {
        if (underlocation) {
            this.tokens.push(this.buildToken(rule.name, ['/dashboard/account/location_details', rule.thing.location.id, 'thing_details', rule.thing.id, 'rules', rule.id], rule.name));
        } else {
            this.tokens.push(this.buildToken(rule.name, ['/dashboard/account/thing_details', rule.thing.id, 'rules', rule.id], rule.name));
        }
        return this;
    }

    addCustomers(): BreadcrumbService {
        let link = null;
        if (this.isCustomersPagePresent) {
            link = ['/dashboard/customers']
        }
        this.tokens.push(this.buildToken('customers', link, null, null, null, 'breadcrumb-list'));
        return this;
    }

    addCustomer(customer: Customer): BreadcrumbService {
        this.tokens.push(this.buildToken(customer.name, ['/dashboard/customer_details', customer.id], customer.name));
        return this;
    }

    addLocations(customer: Customer): BreadcrumbService {
        let link = null;
        if (customer) {
            link = ['/dashboard/customer_details', customer.id]
        }
        this.tokens.push(this.buildToken('locations', link, null, null, null, 'breadcrumb-list'));
        return this;
    }

    addLocation(location: Loc): BreadcrumbService {
        this.tokens.push(this.buildToken(location.name, ['/dashboard/location_details', location.id], location.name));
        return this;
    }

    addThings(location: Loc): BreadcrumbService {
        let link = null;
        if (location) {
            link = ['/dashboard/location_details', location.id]
        }
        this.tokens.push(this.buildToken('things', link, null, null, null, 'breadcrumb-list'));
        return this;
    }

    addThing(thing: Thing): BreadcrumbService {
        let label: string;
        if (this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) {
            label = thing.defaultName || thing.name;
        } else if (this.authenticationService.isCustomerUser()) {
            label = thing.name || thing.defaultName;
        } else {
            label = thing.name;
        }
        this.tokens.push(this.buildToken(thing.name, ['/dashboard/thing_details', thing.id], label || " "));
        return this;
    }

    addRule(rule: Rule): BreadcrumbService {
        this.tokens.push(this.buildToken(rule.name, ['/dashboard/rules', rule.id], rule.name));
        return this;
    }

    addUser(user: User): BreadcrumbService {
        const name = this.printUser(user);
        this.tokens.push(this.buildToken(`${name}`, ['/dashboard/users', user.id], `${name}`));
        return this;
    }

    addApiKey(apiKey) {
        this.tokens.push(this.buildToken(apiKey.name, ['/dashboard/local-api-keys', apiKey.id], apiKey.name));
        return this;
    }

    addSubscription(): BreadcrumbService {
        this.tokens.push(this.buildToken('subscription', []));
        return this;
    }

    addWorkSession(workSession: WorkSession) {
        this.tokens.push(this.buildToken(workSession.name, [workSession.endTimestamp ? '/dashboard/historical_work_session_details' : '/dashboard/active_work_session_details', workSession.id], workSession.name));
        return this;
    }

    addThingActivation(location: Loc) {
        this.tokens.push(this.buildToken('newThingActivation', ['/dashboard/location_details', location.id, 'activate']));
        return this;
    }

    build(): void {
        this.subject.next(this.tokens);
    }

    get(): Observable<BreadCrumbToken[]> {
        return this.subject.asObservable();
    }

    getTokens(): BreadCrumbToken[] {
        return this.subject.getValue();
    }

    addPartners(): BreadcrumbService {
        let link = null;
        if (this.isPartnersPagePresent) {
            link = ['/dashboard/partners']
        }
        this.tokens.push(this.buildToken('partners', link));
        return this;
    }

    addPartner(partner: Partner): BreadcrumbService {
        this.tokens.push(this.buildToken(partner.name, ['/dashboard/partner_details', partner.id], partner.name));
        return this;
    }

    sendFeedback(): any {
        this.tokens.push(this.buildToken('feedback', null, 'Feedback'));
        return this;
    }

    addPayment(): BreadcrumbService {
        this.tokens.push(this.buildToken('allPayments', ['/dashboard/allPayments']));
        return this;
    }

    addAlert(alert: Alert) {
        this.tokens.push(this.buildToken(alert.name, ['/dashboard/event_details', alert.id], alert.name));
        return this;
    }

    addTask(task: Task) {
        this.tokens.push(this.buildToken(task.name, ['/dashboard/task_details', task.id], task.name));
        return this;
    }


    private buildToken(name: string, link: any[], label?: string, tabIndex?: number, tabPage?: string, styleClass?: string, isSubTab?: boolean): BreadCrumbToken {
        return {
            name,
            label: label || this.getLabel(name),
            link,
            tabIndex,
            tabPage,
            styleClass,
            isSubTab
        };
    }

    private getLabel(name: string): string {
        return name + 'MenuItem';
    }

    private printUser(user: User): string {
        let printedName: string = '';
        if (isEmpty(user.firstName) && isEmpty(user.lastName)) {
            printedName = user.email;
        } else {
            if (!isEmpty(user.firstName)) {
                printedName = user.firstName;
            }
            if (!isEmpty(user.lastName)) {
                printedName += ' ' + user.lastName;
            }
        }
        return printedName.trim();
    }

    addSubPath(subPath: string): BreadcrumbService {
        if (this.tokens[this.tokens.length - 1]?.isSubTab) {
            this.tokens.pop();
        }
        this.tokens.push(this.buildToken(subPath, null, subPath, null, null, null, true));
        return this;
    }

    addSparePartDefinition(sp: SparePartDefinition): BreadcrumbService {
        this.tokens.push(this.buildToken(sp.name, ['/dashboard/catalog', sp.id], sp.name));
        return this;
    }

    addManageSubscription() {
        this.tokens.push(this.buildToken('subscriptionManagement', ['/dashboard/account/subscription-management'], 'manageSubscriptionLink'));
        return this;
    }

    private checkPages(): void {
        this.isCustomersPagePresent = !!this.uiService.getUiProfilePageByUrl('customers');
        this.isPartnersPagePresent = !!this.uiService.getUiProfilePageByUrl('partners');
    }

    addSubscriptions(): BreadcrumbService {
        this.tokens.push(this.buildToken('subscription', ['/dashboard/bo-subscriptions']));
        return this;
    }

    addPayments(): BreadcrumbService {
        this.tokens.push(this.buildToken('allPayments', ['/dashboard/bo-payments']));
        return this;
    }

}